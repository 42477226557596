<template>
  <div>
    <div class="inner-tabs-simulation">
      <form @submit.prevent="setTimer()">
        <div id="simulation" class="row justify-content-between align-items-center mb">
          <div class="col-lg-5 col-sm-6">
            <div class="row align-items-center mb-3">
              <div class="col-lg-2 col-sm-3">
                <div class="set-text-time no-mb w-100">Topic</div>
              </div>
              <div class="col-lg-10 col-sm-9">
                <input type="text" class="form-control no-radius" v-model="topic" :readonly="isTimerSaved" :required="!isTimerSaved">
              </div>
            </div>
          </div>

          <div class="col-lg-5 col-sm-6">
            <div id="for-ipad" class="row align-items-end mb-3">
              <div class="col-lg-2 col-sm-3">
                <div class="set-text-time w-100">Duration</div>
              </div>
              <div class="col-lg-10 col-sm-9">
                <div class="row gx-2">
                  <div class="col-12 col-sm-12 col-lg-12">
                    <date-picker
                      style="width: 100%"
                      v-model="speakerTime"
                      value-type="format"
                      type="time"
                      placeholder="hh:mm:ss"
                      :disabled="isTimerSaved"
                    ></date-picker>
                  </div>
                </div>
              </div>
            </div>
            <div class="for-btn-action">
              <div class="col-fba s-c">
                <div class="button-split">
                  <button :disabled="isTimerSaved" class="btn btn-action-speaker" type="submit">Save</button>
                </div>
                 <div class="button-split">
                  <button :disabled="isTimerSaved === false || state == 'start'" class="btn btn-action-speaker" type="button" @click="resetTimer">Reset</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>

    <div class="row">
      <div class="col-lg-10 offset-lg-1">
        <div class="box-content-right">
          <div class="progress mb40">
            <div class="progress-bar" role="progressbar" :style="{width: progress}" aria-valuenow="50"
              aria-valuemin="0" aria-valuemax="100"></div>
          </div>

          <div class="time-indicator mb24">
            <span id="remaining">
              <template v-if="remaining !== null">
                {{ remaining }}
              </template>
              <template v-else>
                00:00
              </template>
            </span> / {{ timeLimit }}
          </div>

          <div class="media-button mb40">
            <div class="bm-col">
              <button class="btn btn-media" type="button" :disabled="isTimerSaved === false || state == 'start'" @click="playCounter()">
                <span class="icon-ico-play"></span>
              </button>
            </div>
            <div class="bm-col">
              <button class="btn btn-media" type="button" :disabled="isTimerSaved === false || state == 'pause' || state == 'stop' " @click="pauseCounter()">
                <span class="icon-ico-pause"></span>
              </button>
            </div>
            <div class="bm-col">
              <button class="btn btn-media" type="button" :disabled="isTimerSaved === false || state == 'stop' || state == 'pause'" @click="stopCounter()">
                <span class="icon-ico-stop"></span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import Swal from "sweetalert2";

export default {
  name: "UnModeratedCaucus",
  props: {
    room: {
      type: Object,
      default: () => {
        return {}
      } 
    }
  },
  data() {
    return {
      topic: null,
      hour: 1,
      minute: 0,
      second: 0,
      speakerTime: null,
      isTimerSaved: false,
      remaining: null,
      counter: null,
      state: null
    }
  },
  watch: {
    room() {
      this.getTimer();
    },
    speakerTime(newVal) {
      const time = newVal.split(':');
      this.hour = Number(time[0])
      this.minute = Number(time[1])
      this.second = Number(time[2])
    }
  },
  computed: {
    timeLimit() {
      return moment().hour(this.hour).minute(this.minute).second(this.second).format('HH:mm:ss')
    },
    progress() {
      if (this.remaining !== null && this.timeLimit !== null) {
        const currentHour = moment(this.remaining, 'HH:mm:ss').hour();
        const currentMinute = moment(this.remaining, 'HH:mm:ss').minute();
        const currentSecond = moment(this.remaining, 'HH:mm:ss').second();
        const current = (currentHour * 60 * 60) + (currentMinute * 60) + currentSecond

        const endHour = moment(this.timeLimit, 'HH:mm:ss').hour();
        const endMinute = moment(this.timeLimit, 'HH:mm:ss').minute();
        const endSecond = moment(this.timeLimit, 'HH:mm:ss').second();
        const end = (endHour * 60 * 60) + (endMinute * 60) + endSecond

        const progress = current / end * 100

        if (progress >= 100) {
          this.stopCounter()
        }

        return `${progress}%`
      } else {
        return `0%`
      }
    }
  },
  methods: {
    increment(type) {
      if (type == 'hour') {
        this.hour += 1;
      } else if (type == 'minute' && this.minute <= 59) {
        this.minute += 1;
      } else if (type == 'second' && this.second <= 59){
        this.second += 1;
      }
    },
    decrement(type) {
      if (type == 'hour' && this.hour > 0) {
        this.hour -= 1;
      }
      if (type == 'minute' && this.minute > 0) {
        this.minute -= 1;
      } else if (type == 'second' && this.second > 0) {
        this.second -= 1;
      }
    },
    getTimer() {
      this.$axios.get(`/master/simulation/${this.room.id}/uc_get_detail`)
      .then(response => {
          this.isTimerSaved = true
          this.topic = response.data.data.topic
          this.hour = response.data.data.hours
          this.minute = response.data.data.minutes
          this.second = response.data.data.seconds

          const hour = this.hour < 10 ? `0${this.hour}` : `${this.hour}`
          const minute = this.minute < 10 ? `0${this.minute}` : `${this.minute}`
          const second = this.second < 10 ? `0${this.second}` : `${this.second}`
          this.speakerTime = `${hour}:${minute}:${second}`

          if (response.data.data.left_hours !== null && response.data.data.left_minutes !== null && response.data.data.left_seconds !== null) {
            this.state = 'pause';
            this.remaining = moment().hour(response.data.data.left_hours).minute(response.data.data.left_minutes).second(response.data.data.left_seconds).format('HH:mm:ss')
          }
        })
    },
    setTimer() {
      this.$axios.post(`/master/simulation/${this.room.id}/uc_set_time`, {
        topic: this.topic,
        hours: this.hour,
        minutes: this.minute,
        seconds: this.second
      }).then(response => {
          this.isTimerSaved = true
          Swal.fire("Success", response.data.message, "success");
        })
    },
    resetTimer() {
      this.$axios.post(`/master/simulation/${this.room.id}/uc_set_time`, {
        topic: this.topic,
        hours: 1,
        minutes: 0,
        seconds: 0
      }).then(() => {
          this.isTimerSaved = false;
          this.hour = 1;
          this.minute = 0;
          this.second = 0;
          this.speakerTime = null;
          clearInterval(this.counter);
          this.remaining = null;

          this.$emit('setCurrent', {
            tab: "Unmoderated Caucus",
            title: "",
            country: "",
            flag: "",
            type: "reset",
            topic: this.topic,
            duration_hours: this.hour,
            duration_minutes: this.minute,
            duration_seconds: this.second,
            minutes: 0,
            seconds: 0,
            remaining_duration_hours: 0,
            remaining_duration_minutes: 0,
            remaining_duration_seconds: 0,
            remaining_minutes: 0,
            remaining_seconds: 0
          })
          
          this.$axios.post(`/master/simulation/${this.room.id}/uc_stop_timer`, {
            _method: 'PATCH',
          })
            .then(response => {
              console.log(response.data)
            })
          
        })
    },
    async playCounter() {
      this.state = 'start'
      let count = this.remaining !== null ? this.remaining : moment(this.timeLimit, 'HH:mm:ss').hour(0).minute(0).second(0)
      this.counter = setInterval(() => {
        if (moment(count, 'HH:mm:ss').isBefore(moment(this.timeLimit, 'HH:mm:ss'))) {
          count =  moment(count, 'HH:mm:ss').add(1, 'second').format('HH:mm:ss')
          this.remaining = count;
        }
      }, 1000)
      await this.$axios.post(`/master/simulation/${this.room.id}/uc_start_timer`, {
          _method: 'PATCH',
          left_hours: moment(this.remaining, 'HH:mm:ss').hour(),
          left_minutes: moment(this.remaining, 'HH:mm:ss').minute(),
          left_seconds: moment(this.remaining, 'HH:mm:ss').second()
        })
          .then(response => {
            console.log(response.data)
          })
      this.$emit('setCurrent', {
        tab: "Unmoderated Caucus",
        title: this.topic,
        country: "",
        flag: "",
        type: "start",
        duration_hours: this.hour,
        duration_minutes: this.minute,
        duration_seconds: this.second,
        minutes: 0,
        seconds: 0,
        remaining_duration_hours: this.remaining !== null ? moment(this.remaining, 'HH:mm:ss').hour() : 0,
        remaining_duration_minutes: this.remaining !== null ? moment(this.remaining, 'HH:mm:ss').minute() : 0,
        remaining_duration_seconds: this.remaining !== null ? moment(this.remaining, 'HH:mm:ss').second() : 0,
        remaining_minutes: 0,
        remaining_seconds: 0
      })
    },
    async pauseCounter() {
      this.state = 'pause'
      clearInterval(this.counter)
      await this.$axios.post(`/master/simulation/${this.room.id}/uc_pause_timer`, {
        _method: 'PATCH',
        left_hours: moment(this.remaining, 'HH:mm:ss').hour(),
        left_minutes: moment(this.remaining, 'HH:mm:ss').minute(),
        left_seconds: moment(this.remaining, 'HH:mm:ss').second()
      })
      .then(response => {
          console.log(response.data)
        })
      this.$emit('setCurrent', {
        tab: "Unmoderated Caucus",
        title: this.topic,
        country: "",
        flag: "",
        type: "pause",
        duration_hours: this.hour,
        duration_minutes: this.minute,
        duration_seconds: this.second,
        minutes: 0,
        seconds: 0,
        remaining_duration_hours: moment(this.remaining, 'HH:mm:ss').hour(),
        remaining_duration_minutes: moment(this.remaining, 'HH:mm:ss').minute(), 
        remaining_duration_seconds: moment(this.remaining, 'HH:mm:ss').second(),
        remaining_minutes: 0,
        remaining_seconds: 0
      })
    },
    stopCounter() {
      this.state = 'stop'
      clearInterval(this.counter)
      this.remaining = this.timeLimit
      this.$axios.post(`/master/simulation/${this.room.id}/uc_stop_timer`, {
        _method: 'PATCH',
      })
        .then(response => {
          console.log(response.data)
        })
      this.$emit('setCurrent', {
        tab: "Unmoderated Caucus",
        title: this.topic,
        country: "",
        flag: "",
        type: "stop",
        duration_hours: this.hour,
        duration_minutes: this.minute,
        duration_seconds: this.second,
        minutes: 0,
        seconds: 0,
        remaining_duration_hours: 0,
        remaining_duration_minutes: 0,
        remaining_duration_seconds: 0,
        remaining_minutes: 0,
        remaining_seconds: 0
      })
    }
  }
}
</script>

<style scoped>
button:disabled {
  pointer-events: auto !important;
  cursor: not-allowed !important;
}
input:read-only {
  background-color: white !important;
}
</style>