<template>
  <div class="inner-tabs-simulation">
    <div v-if="detailAssignment === null">
      <!-- <section class="show-item">
        <div class="container mt-4">
          <div class="row">
            <div class="col-lg-6 col-sm-6">
              <div class="create-form">
                <label class="label-form">Show Entries</label>
                <select class="form-select black" name="per_page" id="per_page" v-model="perPage">
                  <option v-for="(page, index) in pageOptions" :key="index" :value="page">{{ page }}</option>
                </select>
              </div>
            </div>
            <div class="col-lg-6 col-sm-6">
              <div class="create-form">
                <label class="label-form">Search</label>
                <input type="text" name="search" id="search-participant" class="form-control" placeholder="Search..." v-model="filter">
              </div>
            </div>
          </div>
        </div>
      </section> -->
      <!-- Table -->
      <b-table
        outlined
        ref="table"
        :items="fetchAssignment"
        :fields="fields"
        responsive="sm"
        :per-page="perPage"
        :current-page="currentPage"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        :filter="filter"
        :empty-text="'There are no records to show'"
        :empty-filtered-text="'There are no records matching your request'"
        class="table-manage"
      >
        <!-- status -->
        <template v-slot:cell(status_text)="data">
          <div class="box-status approved" v-if="data.item.status_text == 'Approved'">{{ data.item.status_text }}</div>
          <div class="box-status reject" v-if="data.item.status_text == 'Rejected'">{{ data.item.status_text }}</div>
          <div class="box-status aprroval" v-if="data.item.status_text == 'Submitted'">{{ data.item.status_text }}</div>
        </template>
        <!-- Aciton -->
        <template v-slot:cell(action)="data">
          <div class="box-btn-action">
            <template v-if="data.item.status_text == 'Submitted'">
              <button type="button" class="btn btn-action mx-1" @click="approveId = data.item.id">
                <span class="icon-ico-check"></span>
              </button>
              <button type="button" class="btn btn-action mx-1" @click="rejectId = data.item.id">
                <span class="icon-ico-close"></span>
              </button>
            </template>
            <button type="button" class="btn btn-action mx-1" @click="getDetail(data.item.id)">
              <span class="icon-ico-eye"></span>
            </button>
          </div>
        </template>
      </b-table>
      <!-- <div class="filter-row">
        <div class="ft-filter">Showing {{ startRow }} - {{ untilRow }} of {{ totalRows }} list</div>
        <div class="button-filter">
          <ul class="pagination pagination-rounded mb-0">
            <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="perPage"></b-pagination>
          </ul>
        </div>
      </div> -->
    </div>

    <div v-if="detailAssignment !== null">
      <div class="box-candidate">
        <div class="bc-head outcome justify-content-start">
          WORKING PAPER
        </div>
        <div class="bc-content p25">
          <div class="row">
            <div class="col-lg-6 col-sm-6">
              <div class="parti-intro">
                <div class="d-flex">
                  <div class="title-intro">Committe</div>
                  <div class="mx-2">:</div>
                  <div class="intro-parti-desc">
                    <p class="font-medium">{{ detailAssignment.committee }}</p>
                  </div>
                </div>
                <div class="d-flex">
                  <div class="title-intro">Subject Topic</div>
                  <div class="mx-2">:</div>
                  <div class="intro-parti-desc">
                    <p class="font-medium">{{ detailAssignment.subject_topic }}</p>
                  </div>
                </div>
                <div class="d-flex">
                  <div class="title-intro">Alliance</div>
                  <div class="mx-2">:</div>
                  <div class="intro-parti-desc">
                    <p class="font-medium">{{ detailAssignment.alliance_name }}</p>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-6 col-sm-6">
              <div class="date-for-detail">
                <div class="title-intro">Upload Date</div>
                <div class="mx-2">:</div>
                <div class="intro-parti-desc date-upload">
                  <p class="font-medium">{{ detailAssignment.upload_date }}</p>
                </div>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-lg-4 col-sm-6">
              <div class="d-flex">
                <div class="title-intro mb-2">Member</div>
                <div class="mx-2">:</div>
              </div>
              <ul class="list-country">
                <li v-for="(signator, index) in detailAssignment.signatories_country" :key="index">{{ signator }}</li>
              </ul>
            </div>
            <div class="col-lg-4 col-sm-6">
              <div class="d-flex">
                <div class="title-intro mb-2">Sponsor</div>
                <div class="mx-2">:</div>
              </div>
              <ul class="list-country">
                <li v-for="(sponsor, index) in detailAssignment.sponsors_country" :key="index">{{ sponsor }}</li>
              </ul>
            </div>
          </div>

          <div class="info-file">
            <div class="for-link-file">
              <a :href="detailAssignment.paper_link" class="file-download" target="_blank">
                <img src="/img/document-icon.svg" width="13" class="img-fluid"> 
                {{ detailAssignment.paper }} &nbsp;<span class="icon-ico-download"></span>
              </a>
            </div>
            <div class="for-button-app">
              <div class="row">
                <div class="col-lg-6 col-sm-6">
                  <button id="top-mobile" class="btn btn-delete w-100" @click="detailAssignment = null" v-if="detailAssignment.status_text == 'Submitted'">
                    Back
                  </button>
                </div>
                <div class="col-lg-6 col-sm-6">
                  <button id="top-mobile" class="btn btn-orange w-100 approve-list" type="button" @click="approveId = detailAssignment.id"  v-if="detailAssignment.status_text == 'Submitted'">
                    Approve
                  </button>
                  <button id="top-mobile" class="btn btn-delete w-100" @click="detailAssignment = null" v-if="detailAssignment.status_text != 'Submitted'">
                    Back
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- popup confirm approve participant -->
    <div class="popup-overlay" :class="{ show: approveId}" v-if="approveId">
      <div class="inner-delete" :class="{ popup: !finishApprove}">
        <div class="title-info">Confirmation</div>
        <p>Are you sure want to approve this assignment?</p>
        <div class="row g-2">
          <div class="col-6">
            <button type="button" class="btn btn-delete w-100" id="cancel-start-simulation" @click="approveId = null">Cancel</button>
          </div>
          <div class="col-6">
            <button type="button" class="btn btn-orange w-100" id="submit-start-simulation" @click="approve">Yes</button>
          </div>
        </div>
      </div>
      <div class="box-thanks" :class="{ show: finishApprove }">
        <img src="/img/check.svg" class="img-fluid">
        <div class="ttl-oke">Approve Assignment Successfully</div>
        <p></p>
        <button type="button" class="btn btn-orange btn-radius for-ok" id="ok" @click="[approveId = null, finishApprove = false]">Ok</button>
      </div>
    </div>

    <!-- popup confirm reject participant -->
    <div class="popup-overlay" :class="{ show: rejectId}" v-if="rejectId">
      <div class="inner-delete" :class="{ popup: !finishReject}">
        <div class="title-info">Confirmation</div>
        <p>Are you sure want to reject this assignment ?</p>
        <div class="row g-2">
          <div class="col-6">
            <button type="button" class="btn btn-delete w-100" id="cancel-start-simulation" @click="rejectId = null">Cancel</button>
          </div>
          <div class="col-6">
            <button type="button" class="btn btn-orange w-100" id="submit-start-simulation" @click="reject">Yes</button>
          </div>
        </div>
      </div>
      <div class="box-thanks" :class="{ show: finishReject }">
        <img src="/img/check.svg" class="img-fluid">
        <div class="ttl-oke">Reject Assignment Successfully</div>
        <p></p>
        <button type="button" class="btn btn-orange btn-radius for-ok" id="ok" @click="[rejectId = null, finishReject = false]">Ok</button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Assignment",
  props: {
    room: {
      type: Object,
      default: () => {
        return {}
      } 
    }
  },
  watch: {
    room() {
      this.$refs.table.refresh();
      this.getDetailRoom();
    },
  },
  data() {
    return {
      roomDetail: {},
      totalRows: 0,
      currentPage: 1,
      perPage: 100,
      filter: null,
      sortBy: "created_at",
      sortDesc: false,
      pageOptions: [10, 25, 50, 100],
      fields: [
        { key: "alliance_name", label: "ALLIANCE", sortable: true },
        { key: "sponsor_country", label: "SPONSOR", sortable: true },
        // { key: "paper", label: "PAPER", sortable: true , thClass: "papertd"},
        { key: "status_text", label: "STATUS", sortable: true },
        { key: "upload_date", label: "UPLOAD", sortable: true },
        'ACTION'
      ],
      detailAssignment: null,
      rejectId: null,
      finishReject: false,
      approveId: null,
      finishApprove: false
    }
  },
  computed: {
    startRow() {
      if (this.currentPage == 1) {
        return 1
      } else {
        return (this.perPage * (this.currentPage - 1 )) + 1
      }
    },
    untilRow() {
      if ((this.currentPage * this.perPage) <= this.totalRows) {
        return this.currentPage * this.perPage
      } else {
        return this.totalRows
      }
    }
  },
  methods: {
    async fetchAssignment(ctx) {
      if (this.room.id === undefined) {
        this.totalRows = 0;
        return []
      } else {
        const promise = this.$axios.get(`/master/simulation/${this.room.id}/assignment_list`, {
          params: {
            page: ctx.currentPage,
            per_page: ctx.perPage,
            search: ctx.filter,
            sortBy: ctx.sortBy,
            sortType: (ctx.sortDesc)? 'asc' : 'desc',
          }
        })

        // Must return a promise that resolves to an array of items
        return promise.then(response => {
          // Pluck the array of items off our axios response
          const items = response.data.data.data
          this.totalRows = response.data.data.meta.total
          // Must return an array of items or an empty array if an error occurred
          return items || []
        })
      }
    },
    getDetailRoom() {
      this.$axios.get(`/master/room/${this.room.id}`)
        .then(response => {
          this.roomDetail = response.data.data
        })
    },
    approve() {
      this.$axios.patch(`/master/simulation/${this.room.id}/assignment_approve`, {
        assignment_id: this.approveId,
      })
        .then(() => {
          this.finishApprove = true;
          this.detailAssignment = null;
          this.$refs.table.refresh();
        })
    },
    reject() {
      this.$axios.patch(`/master/simulation/${this.room.id}/assignment_reject`, {
        assignment_id: this.rejectId,
      })
        .then(() => {
          this.finishReject = true;
          this.$refs.table.refresh();
        })
    },
    getDetail(id) {
       this.$axios.get(`/master/simulation/${this.room.id}/assignment_detail`, {
         params: {
           assignId: id
         }
       })
        .then(response => {
          this.detailAssignment = response.data.data
        })
    }
  }
}
</script>

<style type="css" scoped>
.filter-row {
  padding: 0px 24px 0px 24px
}
</style>