<template>
  <div class="container">
    <section class="for-parti">
      <div class="parti-intro">
        <div class="d-flex">
          <div class="title-intro">Committe</div>
          <div class="mx-2">:</div>
          <div class="intro-parti-desc">
            <p class="font-medium">{{ header.committee }}</p>
          </div>
        </div>
        <div class="d-flex">
          <div class="title-intro">Subject Topic</div>
          <div class="mx-2">:</div>
          <div class="intro-parti-desc">
            <p class="font-medium">{{ header.subject_topic }}</p>
          </div>
        </div>
      </div>

      <h2 class="detail-title mb24">Participant</h2>
      <participant-list :room="room"></participant-list>

      <h2 class="detail-title mb24">Position Paper</h2>
      <position-paper-list :room="room"></position-paper-list>

    </section>
  </div>
</template>

<script>
import ParticipantList from './ParticipantTable/ParticipantList.vue';
import PositionPaperList from './ParticipantTable/PositionPaperList.vue';

export default {
  name: "Participant",
  props: {
    room: {
      type: Object,
      default: () => {
        return {}
      } 
    }
  },
  components: {
    ParticipantList,
    PositionPaperList
  },
  data() {
    return {
      header: {},
      participants: []
    }
  },
  watch: {
    room() {
      this.getHeader()
    }
  },
  mounted() {
    if (this.room.id !== undefined) {
      this.getHeader()
    }
  },
  methods: {
    getHeader() {
      this.$axios.get(`/master/simulation/participant/${this.room.id}/detail`)
        .then(response => {
          this.header = response.data.data
        })
    }
  }
}
</script>
