<template>
  <div class="box-table">
    <section class="show-item">
      <div class="container mt-4">
        <div class="row">
          <div class="col-lg-6 col-sm-6">
            <div class="create-form">
              <label class="label-form">Show Entries</label>
              <select class="form-select black" name="per_page" id="per_page" v-model="perPage">
                <option v-for="(page, index) in pageOptions" :key="index" :value="page">{{ page }}</option>
              </select>
            </div>
          </div>
          <div class="col-lg-6 col-sm-6">
            <div class="create-form">
              <label class="label-form">Search</label>
              <input type="text" name="search" id="search-position-paper" class="form-control" placeholder="Search..." v-model="filter">
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- Table -->
    <b-table
      outlined
      ref="table"
      :items="fetchPositionPaper"
      :fields="fields"
      responsive="sm"
      :per-page="perPage"
      :current-page="currentPage"
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
      :filter="filter"
      :empty-text="'There are no records to show'"
      :empty-filtered-text="'There are no records matching your request'"
      class="table-manage"
    >
      <!-- status -->
      <template v-slot:cell(status)="data">
        <div class="box-status approved" v-if="data.item.status == 'Approved'">{{ data.item.status }}</div>
        <div class="box-status reject" v-if="data.item.status == 'Rejected'">{{ data.item.status }}</div>
        <div class="box-status aprroval" v-if="data.item.status == 'Submitted'">{{ data.item.status }}</div>
      </template>
      <template v-slot:cell(upload_date)="data">
        {{ data.item.upload_date | date_time }}
      </template>
      <!-- Aciton -->
      <template v-slot:cell(action)="data">
        <div class="box-btn-action">
          <router-link :to="`/simulation/${$route.params.slug}/position-paper/${data.item.id}/${data.item.participant_id}`" class="btn btn-action mx-1">
            <span class="icon-ico-eye"></span>
          </router-link>
        </div>
      </template>
    </b-table>
    <div class="filter-row">
      <div class="ft-filter">Showing {{ startRow }} - {{ untilRow }} of {{ totalRows }} list</div>
      <div class="button-filter">
        <ul class="pagination pagination-rounded mb-0">
          <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="perPage"></b-pagination>
        </ul>
      </div>
    </div>
    <div class="button-generate" v-if="roomDetail.status_simulation < 3">
      <div class="bg-left">
        <button class="btn btn-orange block-mobile" type="button" @click="showStartConfirmationPopup = true">Go to Simulation</button>
      </div>
    </div>

    <!-- popup confirm reject position paper -->
    <div class="popup-overlay" :class="{ show: rejectId}" v-if="rejectId">
      <div class="inner-delete" :class="{ popup: !finishReject}">
        <div class="title-info">Confirmation</div>
        <p>Are you sure want to reject this position paper ?</p>
        <div class="row g-2">
          <div class="col-6">
            <button type="button" class="btn btn-delete w-100" id="cancel-start-simulation" @click="rejectId = null">Cancel</button>
          </div>
          <div class="col-6">
            <button type="button" class="btn btn-orange w-100" id="submit-start-simulation" @click="reject">Yes</button>
          </div>
        </div>
      </div>
      <div class="box-thanks" :class="{ show: finishReject }">
        <img src="/img/check.svg" class="img-fluid">
        <div class="ttl-oke">Reject Position Paper Successfully</div>
        <p></p>
        <button type="button" class="btn btn-orange btn-radius for-ok" id="ok" @click="[rejectId = null, finishReject = false]">Ok</button>
      </div>
    </div>

    <!-- popup confirm approve position paper -->
    <div class="popup-overlay" :class="{ show: approveId}" v-if="approveId">
      <div class="inner-delete" :class="{ popup: !finishApprove}">
        <div class="title-info">Confirmation</div>
        <p>Are you sure want to approve this position paper ?</p>
        <div class="row g-2">
          <div class="col-6">
            <button type="button" class="btn btn-delete w-100" id="cancel-start-simulation" @click="approveId = null">Cancel</button>
          </div>
          <div class="col-6">
            <button type="button" class="btn btn-orange w-100" id="submit-start-simulation" @click="approve">Yes</button>
          </div>
        </div>
      </div>
      <div class="box-thanks" :class="{ show: finishApprove }">
        <img src="/img/check.svg" class="img-fluid">
        <div class="ttl-oke">Approve Position Paper Successfully</div>
        <p></p>
        <button type="button" class="btn btn-orange btn-radius for-ok" id="ok" @click="[approveId = null, finishApprove = false]">Ok</button>
      </div>
    </div>

    <!-- popup confirm start simulation -->
    <div class="popup-overlay" :class="{ show: showStartConfirmationPopup}" v-if="showStartConfirmationPopup">
      <div class="inner-delete popup" :class="{ popup: showStartConfirmationPopup }">
        <div class="title-info">Confirmation</div>
        <p>Are you sure want to start simulation this room?</p>
        <div class="row g-2">
          <div class="col-6">
            <button type="button" class="btn btn-delete w-100" id="cancel-reset" @click="showStartConfirmationPopup = false">Cancel</button>
          </div>
          <div class="col-6">
            <button type="button" class="btn btn-orange w-100" id="submit-reset" @click="start">Yes</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Swal from "sweetalert2";

export default {
  name: "PositionPaperList",
  props: {
    room: {
      type: Object,
      default: () => {
        return {}
      } 
    }
  },
  watch: {
    room() {
      this.$refs.table.refresh();
      this.getDetailRoom();
    },
  },
  data() {
    return {
      roomDetail: {},
      totalRows: 0,
      currentPage: 1,
      perPage: 10,
      filter: null,
      sortBy: "created_at",
      sortDesc: false,
      pageOptions: [10, 25, 50, 100],
      fields: [
        { key: "country", label: "COUNTRY", sortable: true },
        { key: "paper", label: "PAPER", sortable: true },
        { key: "status", label: "STATUS", sortable: true },
        { key: "upload_date", label: "UPLOAD DATE", sortable: true },
        'ACTION'
      ],
      rejectId: null,
      finishReject: false,
      approveId: null,
      finishApprove: false,
      showStartConfirmationPopup: false,
      finishStart: false
    }
  },
  computed: {
    startRow() {
      if (this.currentPage == 1) {
        return 1
      } else {
        return (this.perPage * (this.currentPage - 1 )) + 1
      }
    },
    untilRow() {
      if ((this.currentPage * this.perPage) <= this.totalRows) {
        return this.currentPage * this.perPage
      } else {
        return this.totalRows
      }
    }
  },
  methods: {
    async fetchPositionPaper(ctx) {
      if (this.room.id === undefined) {
        this.totalRows = 0;
        return []
      } else {
        const promise = this.$axios.get(`/master/simulation/position_paper`, {
          params: {
            page: ctx.currentPage,
            per_page: ctx.perPage,
            search: ctx.filter,
            sortBy: ctx.sortBy,
            sortType: (ctx.sortDesc)? 'asc' : 'desc',
            roomId: this.room.id,
          }
        })

        // Must return a promise that resolves to an array of items
        return promise.then(response => {
          // Pluck the array of items off our axios response
          const items = response.data.data.data
          this.totalRows = response.data.data.meta.total
          // Must return an array of items or an empty array if an error occurred
          return items || []
        })
      }
    },
    getDetailRoom() {
      this.$axios.get(`/master/room/${this.room.id}`)
        .then(response => {
          this.roomDetail = response.data.data
        })
    },
    approve() {
      this.$axios.patch(`/master/simulation/position_paper/${this.approveId}/approve`)
        .then(() => {
          this.finishApprove = true;
          this.$refs.table.refresh();
        })
    },
    reject() {
      this.$axios.patch(`/master/simulation/position_paper/${this.rejectId}/reject`)
        .then(() => {
          this.finishReject = true;
          this.$refs.table.refresh();
        })
    },
    start() {
      this.$axios.patch('/master/simulation/start', {
        room_id: this.room.id,
      })
        .then(response => {
          Swal.fire("Success", response.data.message, "success");
          this.$router.push(`/simulation/${this.roomDetail.slug}#simulation`)
        })
    }
  }
}
</script>

<style type="css" scoped>
.filter-row {
  padding: 0px 24px 0px 24px
}
</style>