<template>
  <div class="inner-tabs-simulation">
    <div id="simulation" class="row align-items-center justify-content-between">
      <div class="col-lg-5 col-sm-6">
        <div class="set-time mb-3">
          <div class="set-text-time">
            <span v-if="isTimerSaved === false">Set</span> Speaker Time
          </div>
          <div class="set-time-form">
            <form @submit.prevent="setTimer()">
              <div class="stf-inner">
                <div class="pt-4 mx-3">
                  <date-picker
                    v-model="speakerTime"
                    format="mm:ss"
                    value-type="format"
                    type="time"
                    placeholder="mm:ss"
                    :disabled="isTimerSaved"
                  ></date-picker>
                </div>
                <div>
                  <button type="submit" v-if="isTimerSaved === false" :disabled="speakerTime === null" class="btn btn-action-speaker" style="margin-top: 26px">Save</button>
                  <button type="button" v-if="isTimerSaved === true" :disabled="state === null || state == 'start'" @click="resetTimer()" class="btn btn-action-speaker" style="margin-top: 26px">Reset</button>
                </div>
              </div>
            </form>
          </div>
        </div>
        <div class="box-candidate mb-3 mh-1">
          <div class="bc-head">
            <div class="sl">Speaker List</div>
            <div class="dropdown">
              <button class="btn btn-add-speaker" type="button" id="dropdownMenuButton1"
                data-bs-toggle="dropdown" aria-expanded="false">
                Add Speaker <span class="icon-ico-plus"></span>
              </button>
              <ul class="dropdown-menu speaker dropdown-menu-end"
                aria-labelledby="dropdownMenuButton1">
                <template v-for="(participant, index) in participants">
                  <li :key="index">
                    <button type="button" class="dropdown-item" href="#" @click="addSpeaker(participant)">
                      {{ participant.name }}
                    </button>
                  </li>
                </template>
              </ul>
            </div>
          </div>
          <div class="bc-content">
            <ul class="nav nav-tabs" id="speakerTab" role="tablist">
              <template v-for="(speaker, index) in speakers">
                <li :key="index" class="nav-item" role="presentation">
                  <a 
                    class="nav-link" :id="`speaker-${speaker.id}`" data-bs-toggle="tab"
                    data-bs-target="#usa" type="button" role="tab" aria-controls="usa"
                    :aria-selected="[speaker == activeSpeaker ? true : false]"
                    :class="{active: speaker == activeSpeaker}"
                    @click="setActiveSpeaker(speaker)"
                  >
                    {{ speaker.name }}
                  </a>
                </li>
              </template>
            </ul>
          </div>
        </div>

        <div class="for-btn-action">
          <div class="inner-fba">
            <div class="col-fba">
              <div class="button-split">
                <button type="button" class="btn btn-action-speaker" @click="changeSpeaker(-1)" :disabled="speakers.length == 0">Up</button>
              </div>
              <div class="button-split">
                <button type="button" class="btn btn-action-speaker" @click="changeSpeaker(1)" :disabled="speakers.length == 0">Down</button>
              </div>
            </div>
            <div class="col-fba">
              <div class="button-split">
                <button type="button" class="btn btn-action-speaker" @click="deleteSpeaker()" :disabled="speakers.length == 0">Delete</button>
              </div>
              <div class="button-split">
                <button class="btn btn-action-speaker" @click="clearSpeaker()" :disabled="speakers.length == 0">Clear</button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-lg-5 col-sm-6">
        <div class="box-content-right" v-if="activeSpeaker">
          <div class="box-flag">
            <img v-if="activeSpeaker.type === 'ngo'" src="/img/ngo.png" style="max-width: 240px; border: 1px solid" class="img-fluid" alt="Flag">
            <img v-else :src="activeSpeaker.flag" style="max-width: 240px; border: 1px solid" class="img-fluid" alt="Flag">
            <p>{{ activeSpeaker.name }}</p>
          </div>

          <div class="progress mb40">
            <div class="progress-bar" role="progressbar" :style="{width: progress}" aria-valuenow="50" aria-valuemin="0" aria-valuemax="100"></div>
          </div>

          <div class="time-indicator mb24">
            <span id="remaining">
              <template v-if="remaining !== null">
                {{ remaining }}
              </template>
              <template v-else>
                00:00
              </template>
            </span> / {{ timeLimit }}
          </div>

          <div class="media-button mb40">
            <div class="bm-col">
              <button class="btn btn-media" type="button" :disabled="isTimerSaved === false || state == 'start'" @click="playCounter()">
                <span class="icon-ico-play"></span>
              </button>
            </div>
            <div class="bm-col">
              <button class="btn btn-media" type="button" :disabled="isTimerSaved === false || state == 'pause' || state == 'stop'" @click="pauseCounter()">
                <span class="icon-ico-pause"></span>
              </button>
            </div>
            <div class="bm-col">
              <button class="btn btn-media" type="button" :disabled="isTimerSaved === false || state == 'stop' || state == 'pause'" @click="stopCounter()">
                <span class="icon-ico-stop"></span>
              </button>
            </div>
          </div>

          <div class="for-btn-action">
            <div class="col-fba next">
              <div class="button-split">
                <button class="btn btn-action-speaker" :disabled="isTimerSaved === false || state == 'start' || progress == '100%'" @click="showPopUpDelegate = true">Yield to delegate</button>
              </div>
              <div class="button-split">
                <button type="button" class="btn btn-action-speaker" :disabled="isTimerSaved === false || state == 'start' || isLastSpeaker" @click="nextSpeaker()">Next Speaker</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- popup confirm join -->
    <div class="popup-overlay" :class="{ show: showPopUpDelegate }">
      <div class="inner-delete" :class="{ popup: showPopUpDelegate }">
        <form @submit.prevent="submitDelegate()">
          <div class="title-info mb-3">Delegate Speaker</div>
          <div class="label-popup mb-2">Choose Speaker</div>
          <select class="form-select mb-3" id="join-country" v-model="delegateSpeaker" required>
            <option :value="null">Speaker</option>
            <template v-for="(speaker, i) in speakers">
              <option :key="i" :value="speaker" v-if="speaker !== activeSpeaker">{{ speaker.name }}</option>
            </template>
          </select>
          <div class="row g-2">
            <div class="col-6">
              <button type="button" class="btn btn-delete  btn-radius w-100" id="cancel-join" @click="showPopUpDelegate = null">Cancel</button>
            </div>
            <div class="col-6">
              <button type="submit" class="btn btn-orange btn-radius for-submit w-100" id="submit-join">Submit</button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import Swal from "sweetalert2";

export default {
  name: "GeneralSpeaker",
  props: {
    room: {
      type: Object,
      default: () => {
        return {}
      } 
    }
  },
  data() {
    return {
      speakerTime: null,
      minute: 1,
      second: 0,
      isTimerSaved: false,
      remaining: null,
      counter: null,
      participants: [],
      speakers: [],
      activeSpeaker: null,
      state: null,
      showPopUpDelegate: false,
      delegateSpeaker: null
    }
  },
  watch: {
    room() {
      this.getParticipants();
      this.getSpeakers();
      this.getTimer();
    },
    speakerTime(newVal) {
      const time = newVal.split(':');
      this.minute = Number(time[0])
      this.second = Number(time[1])
    }
  },
  computed: {
    timeLimit() {
      return moment().minute(this.minute).second(this.second).format('mm:ss')
    },
    progress() {
      if (this.remaining !== null && this.timeLimit !== null) {
        const currentMinute = moment(this.remaining, 'mm:ss').minute();
        const currentSecond = moment(this.remaining, 'mm:ss').second();
        const current = (currentMinute * 60) + currentSecond

        const endMinute = moment(this.timeLimit, 'mm:ss').minute();
        const endSecond = moment(this.timeLimit, 'mm:ss').second();
        const end = (endMinute * 60) + endSecond

        const progress = current / end * 100
        
        if (progress >= 100) {
          this.stopCounter()
        }

        return `${progress}%`
      } else {
        return `0%`
      }
    },
    isLastSpeaker() {
      const index = this.speakers.findIndex(speaker => speaker == this.activeSpeaker)
      if ((index + 1) < this.speakers.length) {
        return false;
      } else {
        return true;
      }
    }
  },
  methods: {
    increment(type) {
      if (type == 'minute' && this.minute <= 59) {
        this.minute += 1;
      } else if (type == 'second' && this.second <= 59){
        this.second += 1;
      }
    },
    decrement(type) {
      if (type == 'minute' && this.minute > 0) {
        this.minute -= 1;
      } else if (type == 'second' && this.second > 0) {
        this.second -= 1;
      }
    },
    getTimer() {
      this.$axios.get(`/master/simulation/${this.room.id}/gs_show_timer`)
      .then(response => {
          this.isTimerSaved = true
          this.state = 'pause'
          this.minute = response.data.data.minutes
          this.second = response.data.data.seconds

          const minute = this.minute < 10 ? `0${this.minute}` : `${this.minute}`
          const second = this.second < 10 ? `0${this.second}` : `${this.second}`
          this.speakerTime = `${minute}:${second}`
        })
    },
    setTimer() {
      this.$axios.post(`/master/simulation/${this.room.id}/gs_set_time`, {
        minutes: this.minute,
        seconds: this.second
      }).then(response => {
          this.isTimerSaved = true
          this.state = 'pause'
          Swal.fire("Success", response.data.message, "success");
        })
    },
    resetTimer() {
      this.$axios.post(`/master/simulation/${this.room.id}/gs_set_time`, {
        minutes: 1,
        seconds: 0
      }).then(() => {
          this.isTimerSaved = false
          this.minute = 1
          this.second = 0
          this.speakerTime = null
          this.state = null
          clearInterval(this.counter);
          this.remaining = null;

          this.$emit('setCurrent', {
            tab: "General Speaker",
            title: "",
            country: "",
            flag: "",
            type: "reset",
            duration_hours: 0,
            duration_minutes: 0,
            duration_seconds: 0,
            minutes: this.minute,
            seconds: this.second,
            remaining_duration_hours: 0,
            remaining_duration_minutes: 0,
            remaining_duration_seconds: 0,
            remaining_minutes: this.remaining !== null ? moment(this.remaining, 'mm:ss').minute() : 0,
            remaining_seconds: this.remaining !== null ? moment(this.remaining, 'mm:ss').second() : 0,
          })
        })
    },
    getParticipants() {
      this.$axios.get(`/master/simulation/${this.room.id}/gs_list_participant`)
        .then(response => {
          this.participants = response.data.data
        })
    },
    async getSpeakers() {
      await this.$axios.get(`/master/simulation/${this.room.id}/gs_list_speaker`)
        .then(response => {
          this.speakers = response.data.data
        })
    },
    async setActiveSpeaker(speaker) {
      if (this.state == 'start') {
        await this.$axios.post(`/master/simulation/${this.room.id}/gs_stop_timer`, {
          _method: 'PATCH',
          gsl_id: this.activeSpeaker.id,
        }).then(response => {
            console.log(response.data)
          })
      }

      this.activeSpeaker = speaker;
      this.state = 'pause'
      this.remaining = null
      clearInterval(this.counter)
    },
    async addSpeaker(participant) {
      // const index = this.speakers.findIndex(speaker => speaker.name == participant.name)
      // if (index < 0) {
      this.$axios.post(`/master/simulation/${this.room.id}/gs_add_speaker`, {
        participant_id: participant.id,
        index: this.speakers.length,
      })
        .then(async () => {
          await this.getSpeakers();
        })
      // }
    },
    async changeSpeaker(step) {
      if (this.activeSpeaker) {
        if (step > 0) { // next or down
          await this.$axios.post(`/master/simulation/${this.room.id}/gs_down_speaker`, {
            _method: "PATCH",
            id: this.activeSpeaker.id,
          })
          .then(async () => {
            await this.getSpeakers();
          })
        } else { // previous or up
          await this.$axios.post(`/master/simulation/${this.room.id}/gs_up_speaker`, {
            _method: "PATCH",
            id: this.activeSpeaker.id,
          })
          .then(async () => {
            await this.getSpeakers();
          })
        }
      }
    },
    async deleteSpeaker() {        
      if (this.activeSpeaker) {
        await this.$axios.post(`/master/simulation/${this.room.id}/gs_remove_speaker`, {
          _method: 'DELETE',
          gsl_id: this.activeSpeaker.id,
        })
          .then(async () => {
            await this.getSpeakers();
            this.activeSpeaker = null;
            this.state = 'pause'
            this.remaining = null
            clearInterval(this.counter)
          })
        this.resetTimer()
      }
    },
    clearSpeaker() {
      this.speakers = []
      this.activeSpeaker = null
      this.state = 'pause'
      this.remaining = null
      clearInterval(this.counter)
      this.$axios.delete(`/master/simulation/${this.room.id}/gs_clear_speaker`)
        .then(response => {
          console.log("clear speaker ", response.data)
        })
      this.resetTimer()
    },
    nextSpeaker() {
      const index = this.speakers.findIndex(speaker => speaker == this.activeSpeaker)
      if ((index + 1) < this.speakers.length) {
        this.pauseCounter()
        this.remaining = null
        this.activeSpeaker = this.speakers[index+1]
      }
    },
    playCounter() {
      this.state = 'start'
      let count = this.remaining !== null ? this.remaining : moment(this.timeLimit, 'mm:ss').minute(0).second(0)
      if (moment(count, 'mm:ss').isBefore(moment(this.timeLimit, 'mm:ss'))) {
        this.$axios.post(`/master/simulation/${this.room.id}/gs_start_timer`, {
          _method: 'PATCH',
          gsl_id: this.activeSpeaker.id,
        }).then(response => {
            console.log(response.data)
          })
      }
      this.counter = setInterval(() => {
        if (moment(count, 'mm:ss').isBefore(moment(this.timeLimit, 'mm:ss'))) {
          count =  moment(count, 'mm:ss').add(1, 'second').format('mm:ss')
          this.remaining = count;
        }
      }, 1000)
      this.$emit('setCurrent', {
        tab: "General Speaker",
        title: "",
        country: this.activeSpeaker.name,
        flag: this.activeSpeaker.flag,
        type: "start",
        duration_hours: 0,
        duration_minutes: 0,
        duration_seconds: 0,
        minutes: this.minute,
        seconds: this.second,
        remaining_duration_hours: 0,
        remaining_duration_minutes: 0,
        remaining_duration_seconds: 0,
        remaining_minutes: this.remaining !== null ? moment(this.remaining, 'mm:ss').minute() : 0,
        remaining_seconds: this.remaining !== null ? moment(this.remaining, 'mm:ss').second() : 0,
      })
    },
    pauseCounter() {
      this.state = 'pause'
      clearInterval(this.counter)
      this.$axios.post(`/master/simulation/${this.room.id}/gs_pause_timer`, {
        _method: 'PATCH',
        gsl_id: this.activeSpeaker.id,
      }).then(response => {
          console.log(response.data)
        })
      this.$emit('setCurrent', {
        tab: "General Speaker",
        title: "",
        country: this.activeSpeaker.name,
        flag: this.activeSpeaker.flag,
        type: "pause",
        duration_hours: 0,
        duration_minutes: 0,
        duration_seconds: 0,
        minutes: this.minute,
        seconds: this.second,
        remaining_duration_hours: 0,
        remaining_duration_minutes: 0,
        remaining_duration_seconds: 0,
        remaining_minutes: moment(this.remaining, 'mm:ss').minute(),
        remaining_seconds: moment(this.remaining, 'mm:ss').second(),
      })
    },
    stopCounter() {
      this.state = 'stop'
      clearInterval(this.counter)
      this.remaining = this.timeLimit
      this.$axios.post(`/master/simulation/${this.room.id}/gs_stop_timer`, {
        _method: 'PATCH',
        gsl_id: this.activeSpeaker.id,
      }).then(response => {
          console.log(response.data)
        })
      this.$emit('setCurrent', {
        tab: "General Speaker",
        title: "",
        country: this.activeSpeaker.name,
        flag: this.activeSpeaker.flag,
        type: "stop",
        duration_hours: 0,
        duration_minutes: 0,
        duration_seconds: 0,
        minutes: this.minute,
        seconds: this.second,
        remaining_duration_hours: 0,
        remaining_duration_minutes: 0,
        remaining_duration_seconds: 0,
        remaining_minutes: 0,
        remaining_seconds: 0,
      })
    },
    submitDelegate() {
      this.activeSpeaker = this.delegateSpeaker;
      this.showPopUpDelegate = false;
    }
  }
}
</script>

<style scoped>
button:disabled {
  pointer-events: auto !important;
  cursor: not-allowed !important;
}
input:read-only {
  background-color: white !important;
}
</style>