<template>
  <div id="simulation-detail-page">
    <section class="tabs-simulation">
      <div class="container">
        <ul class="nav nav-tabs parti-tabs" id="myTab" role="tablist">
          <li class="nav-item" role="presentation">
            <button class="nav-link" @click="tab = 'case-detail'" :class="{ active: tab == 'case-detail' }" id="cd-tab" data-bs-toggle="tab" data-bs-target="#cd" type="button" role="tab"
              aria-controls="cd" :aria-selected="[tab == 'case-detail'? true : false]">Case Details</button>
          </li>
          <li class="nav-item" role="presentation">
            <button class="nav-link" @click="tab = 'participant'"  :class="{ active: tab == 'participant' }" id="participant-tab" data-bs-toggle="tab" data-bs-target="#participant"
              type="button" role="tab" aria-controls="participant" :aria-selected="[tab == 'participant'? true : false]">Participant</button>
          </li>
          <li class="nav-item" role="presentation">
            <button class="nav-link" @click="tab = 'simulation'"  :class="{ active: tab == 'simulation' }" id="simulation-tab" data-bs-toggle="tab" data-bs-target="#simulation" type="button"
              role="tab" aria-controls="simulation" :aria-selected="[tab == 'simulation'? true : false]" :disabled="room2.status_simulation < 3">Simulation</button>
          </li>
          <li class="nav-item" role="presentation">
            <button class="nav-link" @click="tab = 'chat'"  :class="{ active: tab == 'chat' }" id="chat-tab" data-bs-toggle="tab" data-bs-target="#chat" type="button" role="tab"
              aria-controls="chat" :aria-selected="[tab == 'chat'? true : false]">Chat</button>
          </li>
        </ul>
      </div>
      <div class="tab-content" id="myTabContent">
        <div class="tab-pane fade" :class="{ active: tab == 'case-detail', show: tab == 'case-detail' }" id="case-detail" role="tabpanel" aria-labelledby="cd-tab">
          <case-detail :room="room" :room2="room2"></case-detail>
        </div>
        <div class="tab-pane fade" :class="{ active: tab == 'participant', show: tab == 'participant' }" id="participant" role="tabpanel" aria-labelledby="participant-tab">
          <participant :room="room" :room2="room2"></participant>
        </div>
        <div class="tab-pane fade" :class="{ active: tab == 'simulation', show: tab == 'simulation' }" id="simulation" role="tabpanel" aria-labelledby="simulation-tab">
          <simulation :room="room" :room2="room2"></simulation>
        </div>
        <div class="tab-pane fade" :class="{ active: tab == 'chat', show: tab == 'chat' }" id="chat" role="tabpanel" aria-labelledby="chat-tab">
          <chat></chat>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import CaseDetail from './master/CaseDetail.vue'
import Participant from './master/Participant.vue'
import Simulation from './master/Simulation.vue'
import Chat from './user/Chat.vue'
import cookie from "js-cookie";

export default {
  name: "SimulationMasterDetail",
  components: {
    CaseDetail,
    Participant,
    Simulation,
    Chat
  },
  data() {
    return {
      tab: 'case-detail',
      room: {},
      room2: {}
    }
  },
  watch: {
    tab(newVal) {
      console.log(newVal);
      cookie.set('tab', newVal)
    }
  },
  mounted() {
    this.getRoomDetail()
    if (this.$route.hash !== "") {
      console.log(this.$route.hash)
      switch(this.$route.hash) {
        case "#case-detail":
          this.tab = 'case-detail';
          break;
        case "#participant":
          this.tab = 'participant';
          break;
        case "#simulation":
          this.tab = 'simulation';
          break;
        case "#chat":
          this.tab = 'chat';
          break;
      }
    }

    const tabHistory = cookie.get('tab')
    if (tabHistory !== undefined) {
      this.tab = tabHistory
    }
  },
  methods: {
    getRoomDetail() {
      this.$axios.get(`/master/room/overview_detail/${this.$route.params.slug}`)
        .then(response => {
          this.room = response.data.data
          this.getRoomDetail2()
        });
    },
    getRoomDetail2() {
      this.$axios.get(`/master/room/${this.room.id}`)
        .then(response => {
          this.room2 = response.data.data
        })
    }
  }
}
</script>

<style scoped>
button:disabled {
  cursor: not-allowed
}
</style>