<template>
  <div class="container">
    <div class="section-simulation">
      <div class="row">
        <div class="col-lg-6">
          <div class="parti-intro">
            <div class="d-flex">
              <div class="title-intro">Committe</div>
              <div class="mx-2">:</div>
              <div class="intro-parti-desc">
                <p class="font-medium">{{ header.committee }}</p>
              </div>
            </div>
            <div class="d-flex">
              <div class="title-intro">Subject Topic</div>
              <div class="mx-2">:</div>
              <div class="intro-parti-desc">
                <p class="font-medium">{{ header.subject_topic }}</p>
              </div>
            </div>
            <div class="d-flex">
              <div class="title-intro">Country Present</div>
              <div class="mx-2">:</div>
              <div class="intro-parti-desc">
                <p class="font-medium">{{ header.country_present }}</p>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-6">
          <div class="parti-intro">
            <template v-if="header.link_zoom !== null && header.link_zoom !== 'https://null'">
              <div class="d-flex">
                <div class="title-intro">Zoom</div>
                <div class="mx-2">:</div>
                <div class="intro-parti-desc">
                  <p>
                    <a :href="header.link_zoom" target="_blank" class="link-wrap">{{ header.link_zoom }}</a>
                  </p>
                </div>
              </div>
              <template v-for="(meeting, index) in header.credentials">
                <div :key="index">
                  <div class="d-flex">
                    <div class="title-intro">Meeting ID</div>
                    <div class="mx-2">:</div>
                    <div class="intro-parti-desc">
                      <p>
                        {{ meeting.meeting_id }}
                      </p>
                    </div>
                  </div>
                  <div class="d-flex" :key="index">
                    <div class="title-intro">Meeting Password</div>
                    <div class="mx-2">:</div>
                    <div class="intro-parti-desc">
                      <p>
                        {{ meeting.password }}
                      </p>
                    </div>
                  </div>
                </div>
              </template>
            </template>
            <div class="d-flex" v-if="header.link_google_meet !== null && header.link_google_meet !== 'https://null'">
              <div class="title-intro">Google Meet</div>
              <div class="mx-2">:</div>
              <div class="intro-parti-desc">
                <p>
                  <a :href="header.link_google_meet" target="_blank" class="link-wrap">{{ header.link_google_meet }}</a>
                </p>
              </div>
            </div>
          </div>
          <button v-if="roomDetail.status_simulation < 4" class="btn btn-orange block-mobile" type="button" style="float: right; margin-bottom: 10px" @click="showPopUpFinish = true">Finish Simulation</button>
        </div>
      </div>

      <div class="box-inner-simulation">
        <div class="box-simulation">
          <swiper class="nav nav-tabs simu-tabs simulation-slider swiper-container" id="myTab" role="tablist" ref="tabSwiper" :options="tabSwiper">
            <swiper-slide>
              <div class="nav-item" role="presentation">
                <button class="nav-link slide-nav" id="gsl-tab" data-bs-toggle="tab"
                  data-bs-target="#gsl" type="button" role="tab" aria-controls="gsl"
                  :aria-selected="[tab == 'general-speaker'? true : false]"
                  :class="{ active: tab == 'general-speaker' }"
                  :disabled="roomDetail.status_simulation >= 4"
                  @click="tab = 'general-speaker'"
                >
                  General Speaker List
                </button>
              </div>
            </swiper-slide>
            <swiper-slide>
              <div class="nav-item" role="presentation">
                <button class="nav-link slide-nav" id="ss-tab" data-bs-toggle="tab" 
                  data-bs-target="#ss" type="button" role="tab" aria-controls="ss"
                  :aria-selected="[tab == 'single-speaker'? true : false]"
                  :class="{ active: tab == 'single-speaker' }"
                  :disabled="roomDetail.status_simulation >= 4"
                  @click="tab = 'single-speaker'"
                >
                  Single Speaker
                </button>
              </div>
            </swiper-slide>
            <swiper-slide>
              <div class="nav-item" role="presentation">
                <button class="nav-link slide-nav" id="mc-tab" data-bs-toggle="tab" 
                  data-bs-target="#mc" type="button" role="tab" aria-controls="mc" 
                  :aria-selected="[tab == 'moderated-caucus'? true : false]"
                  :class="{ active: tab == 'moderated-caucus' }"
                  :disabled="roomDetail.status_simulation >= 4"
                  @click="tab = 'moderated-caucus'"
                >
                  Moderated Caucus
                </button>
              </div>
            </swiper-slide>
            <swiper-slide>
              <div class="nav-item" role="presentation">
                <button class="nav-link slide-nav" id="uc-tab" data-bs-toggle="tab"
                  data-bs-target="#uc" type="button" role="tab" aria-controls="uc" 
                  :aria-selected="[tab == 'unmoderated-caucus'? true : false]"
                  :class="{ active: tab == 'unmoderated-caucus' }"
                  :disabled="roomDetail.status_simulation >= 4"
                  @click="tab = 'unmoderated-caucus'"
                >
                  Unmoderated Caucus
                </button>
              </div>
            </swiper-slide>
            <swiper-slide>
              <div class="nav-item" role="presentation">
                <button class="nav-link slide-nav" id="pv-tab" data-bs-toggle="tab" 
                  data-bs-target="#pv" type="button" role="tab" aria-controls="pv" 
                  :aria-selected="[tab == 'procedural-voting'? true : false]"
                  :class="{ active: tab == 'procedural-voting' }"
                  :disabled="roomDetail.status_simulation >= 4"
                  @click="tab = 'procedural-voting'"
                >
                  Procedural Voting
                </button>
              </div>
            </swiper-slide>
            <swiper-slide>
              <div class="nav-item" role="presentation">
                <button class="nav-link slide-nav" id="rdv-tab" data-bs-toggle="tab" 
                  data-bs-target="#rdv" type="button" role="tab" aria-controls="rdv" 
                  :aria-selected="[tab == 'roll-call-vote'? true : false]"
                  :class="{ active: tab == 'roll-call-vote' }"
                  @click="tab = 'roll-call-vote'"
                >
                  Roll Call Vote
                </button>
              </div>
            </swiper-slide>
            <swiper-slide>
              <div class="nav-item" role="presentation">
                <button class="nav-link slide-nav" id="ass-tab" data-bs-toggle="tab" 
                  data-bs-target="#ass" type="button" role="tab" aria-controls="ass" 
                  :aria-selected="[tab == 'assignment'? true : false]"
                  :class="{ active: tab == 'assignment' }"
                  @click="tab = 'assignment'"
                >
                  Assignment
                </button>
              </div>
            </swiper-slide>
          </swiper>
          <div class="arrow-tabs">
            <div class="swiper-button-next"></div>
            <div class="swiper-button-prev"></div>
          </div>
          <div class="tab-content" id="myTabContent">
            <div class="tab-pane fade" :class="{ active: tab == 'general-speaker', show: tab == 'general-speaker' }" id="gsl" role="tabpanel" aria-labelledby="gsl-tab">
              <general-speaker :room="room" @setCurrent="setCurrentState"></general-speaker>
            </div>
            <div class="tab-pane fade" :class="{ active: tab == 'single-speaker', show: tab == 'single-speaker' }" id="ss" role="tabpanel" aria-labelledby="ss-tab">
              <single-speaker :room="room" @setCurrent="setCurrentState"></single-speaker>
            </div>
            <div class="tab-pane fade" :class="{ active: tab == 'moderated-caucus', show: tab == 'moderated-caucus' }" id="mc" role="tabpanel" aria-labelledby="mc-tab">
              <moderated-caucus :room="room" @setCurrent="setCurrentState"></moderated-caucus>
            </div>
            <div class="tab-pane fade" :class="{ active: tab == 'unmoderated-caucus', show: tab == 'unmoderated-caucus' }" id="uc" role="tabpanel" aria-labelledby="uc-tab">
              <un-moderated-caucus :room="room" @setCurrent="setCurrentState"></un-moderated-caucus>
            </div>
            <div class="tab-pane fade" :class="{ active: tab == 'procedural-voting', show: tab == 'procedural-voting' }" id="pv" role="tabpanel" aria-labelledby="pv-tab">
              <procedural-voting :room="room" @setCurrent="setCurrentState"></procedural-voting>
            </div>
            <div class="tab-pane fade" :class="{ active: tab == 'roll-call-vote', show: tab == 'roll-call-vote' }" id="rdv" role="tabpanel" aria-labelledby="rdv-tab">
              <roll-call-vote :room="room"></roll-call-vote>
            </div>
            <div class="tab-pane fade" :class="{ active: tab == 'assignment', show: tab == 'assignment' }" id="ass" role="tabpanel" aria-labelledby="ass-tab">
              <assignment :room="room"></assignment>
            </div>
          </div>
        </div>
      </div>
    </div>
    
    <!-- popup confirm finish simulation -->
    <div class="popup-overlay" :class="{ show: showPopUpFinish}">
      <div class="inner-delete" :class="{ popup: showPopUpFinish}">
        <div class="title-info">Confirmation</div>
        <p>Are you sure want to finish simulation this room ?</p>
        <div class="row g-2">
          <div class="col-6">
            <button type="button" class="btn btn-delete w-100" id="cancel-start-simulation" @click="showPopUpFinish = false">Cancel</button>
          </div>
          <div class="col-6">
            <button type="button" class="btn btn-orange w-100" id="submit-start-simulation" @click="finish">Yes</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import GeneralSpeaker from './SimulationTabContent/GeneralSpeaker.vue';
import SingleSpeaker from './SimulationTabContent/SingleSpeaker.vue';
import ModeratedCaucus from './SimulationTabContent/ModeratedCaucus.vue';
import UnModeratedCaucus from './SimulationTabContent/UnModeratedCaucus.vue';
import ProceduralVoting from './SimulationTabContent/ProceduralVoting.vue';
import RollCallVote from './SimulationTabContent/RollCallVote.vue';
import Assignment from './SimulationTabContent/Assignment.vue';
import Swal from "sweetalert2";

export default {
  name: "SimulationMaster",
  components: {
    GeneralSpeaker,
    SingleSpeaker, 
    ModeratedCaucus,
    UnModeratedCaucus,
    ProceduralVoting,
    RollCallVote,
    Assignment
  },
  props: {
    room: {
      type: Object,
      default: () => {
        return {}
      } 
    }
  },
  data() {
    return {
      header: {},
      roomDetail: {},
      tab: 'general-speaker',
      tabSwiper: {
        slidesPerView: 7,
        spaceBetween: 0,
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
        mousewheel: false,
        keyboard: true,
        breakpoints: {
          "320": {
            slidesPerView: 1,
            mousewheel: true,
          },
          "768": {
            slidesPerView: 3,
          },
          "1024": {
            slidesPerView: 4,
          },
          "1200": {
            slidesPerView: 7,
          },
        },
        observer: true,
        observeParents: true,
      }, 
      showPopUpFinish: false
    }
  },
  watch: {
    room() {
      this.getDetailRoom();
      this.getHeader();
    }
  },
  mounted() {
    if (this.room.id !== undefined) {
      this.getDetailRoom();
      this.getHeader();
    }
  },
  methods: {
    getDetailRoom() {
      this.$axios.get(`/master/room/${this.room.id}`)
        .then(response => {
          this.roomDetail = response.data.data

          if (this.roomDetail.status_simulation >= 4) {
            this.tab = 'roll-call-vote'
          }
        })
    },
    getHeader() {
      this.$axios.get(`/master/simulation/${this.room.id}/detail`)
        .then(response => {
          this.header = response.data.data
        })
    },
    finish() {
      this.$axios.patch('/master/simulation/end', {
        room_id: this.room.id,
      })
        .then(response => {
          Swal.fire("Success", response.data.message, "success");
          this.$router.push(`/simulation/${this.roomDetail.slug}#simulation`)
        })
    },
    setCurrentState(data) {
      this.$axios.post(`/master/simulation/${this.room.id}/set_current_state`, {
        tab_active: data.tab,
        title: data.title,
        country: data.country,
        flag: data.flag,
        type: data.type,
        duration_hours: data.duration_hours,
        duration_minutes: data.duration_minutes,
        duration_seconds: data.duration_seconds,
        minutes: data.minutes,
        seconds: data.seconds,
        remaining_duration_hours: data.remaining_duration_hours,
        remaining_duration_minutes: data.remaining_duration_minutes,
        remaining_duration_seconds: data.remaining_duration_seconds,
        remaining_minutes: data.remaining_minutes,
        remaining_seconds: data.remaining_seconds,
      })
        .then(response => {
          console.log(response.data.data)
        })
    }
  }
}
</script>

<style scoped>
.swiper-button-prev, .swiper-container-rtl .swiper-button-next {
  background-image: none !important;
}
.swiper-button-next, .swiper-container-rtl .swiper-button-prev {
  background-image: none !important;
}
button:disabled {
  pointer-events: auto !important;
  cursor: not-allowed !important;
}
</style>