<template>
  <div class="inner-tabs-simulation">
    <div id="simulation" class="row justify-content-between align-items-center">
      <div class="col-lg-5 col-sm-6">
        <form @submit.prevent="setTimer()">
          <div class="set-time mb-3">
            <div class="set-text-time">Topic</div>
            <div class="set-time-form">
              <input type="text" class="form-control no-radius" v-model="topic" :readonly="isTimerSaved" :required="!isTimerSaved">
            </div>
          </div>
          <div class="set-time mb-3">
            <div class="set-text-time">Duration</div>
            <div class="set-time-form">
              <div class="stf-inner">
                <div class="pt-4">
                  <date-picker
                    style="width: 373px"
                    v-model="durationTime"
                    value-type="format"
                    type="time"
                    placeholder="hh:mm:ss"
                    :disabled="isTimerSaved"
                  ></date-picker>
                </div>
              </div>
            </div>
          </div>
          <div class="set-time mb-3">
            <div class="set-text-time"><span v-if="isTimerSaved === false">Set</span> Speaker Time</div>
            <div class="set-time-form">
              <div class="stf-inner">
                <div class="pt-4">
                  <date-picker
                    v-model="speakerTime"
                    format="mm:ss"
                    value-type="format"
                    type="time"
                    placeholder="mm:ss"
                    :disabled="isTimerSaved"
                  ></date-picker>
                </div>

                <div class="button-split mx-3" v-if="!isTimerSaved">
                  <button type="submit" class="btn btn-action-speaker" style="margin-top: 26px">Save</button>
                </div>
                <div class="button-split mx-3" v-if="isTimerSaved">
                  <button :disabled="state == 'start'" type="button" class="btn btn-action-speaker" style="margin-top: 26px" @click="resetTimer">Reset</button>
                </div>
              </div>
            </div>
          </div>
        </form>
        <div class="box-candidate mb-3 mh-1">
          <div class="bc-head">
            <div class="sl">Speaker List</div>
            <div class="dropdown">
              <button class="btn btn-add-speaker" type="button" id="dropdownMenuButton1"
                data-bs-toggle="dropdown" aria-expanded="false">
                Add Speaker <span class="icon-ico-plus"></span>
              </button>
              <ul class="dropdown-menu speaker dropdown-menu-end" aria-labelledby="dropdownMenuButton1">
                <template v-for="(participant, index) in participants">
                  <li :key="index">
                    <button type="button" class="dropdown-item" href="#" @click="addSpeaker(participant)">
                      {{ participant.name }}
                    </button>
                  </li>
                </template>
              </ul>
            </div>
          </div>
          <div class="bc-content">
            <ul class="nav nav-tabs" id="speakerTab" role="tablist">
              <template v-for="(speaker, index) in speakers">
                <li :key="index" class="nav-item" role="presentation">
                  <a 
                    class="nav-link" :id="`speaker-${speaker.id}`"
                    :aria-selected="[speaker == activeSpeaker ? true : false]"
                    :class="{active: speaker == activeSpeaker}"
                    @click="setActiveSpeaker(speaker)"
                  >
                    {{ speaker.name }}
                  </a>
                </li>
              </template>
            </ul>
          </div>
        </div>
        <div class="for-btn-action">
          <div class="inner-fba">
            <div class="col-fba">
              <div class="button-split">
                <button type="button" class="btn btn-action-speaker" @click="changeSpeaker(-1)" :disabled="speakers.length == 0">Up</button>
              </div>
              <div class="button-split">
                <button type="button" class="btn btn-action-speaker" @click="changeSpeaker(1)" :disabled="speakers.length == 0">Down</button>
              </div>
            </div>
            <div class="col-fba">
              <div class="button-split">
                <button type="button" class="btn btn-action-speaker" @click="deleteSpeaker()" :disabled="speakers.length == 0">Delete</button>
              </div>
              <div class="button-split">
                <button class="btn btn-action-speaker" @click="clearSpeaker()" :disabled="speakers.length == 0">Clear</button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-lg-5 col-sm-6">
        <div class="box-content-right" v-if="activeSpeaker">
          <div class="box-flag">
            <img v-if="activeSpeaker.type === 'ngo'" src="/img/ngo.png" style="max-width: 240px; border: 1px solid" class="img-fluid" alt="Flag">
            <img v-else :src="activeSpeaker.flag" style="max-width: 240px; border: 1px solid" class="img-fluid" alt="Flag">
            <p>{{ activeSpeaker.name }}</p>
          </div>

          <div class="progress mb40">
            <div class="progress-bar" role="progressbar" :style="{width: progress2}" aria-valuemin="0" aria-valuemax="100"></div>
          </div>

          <div class="time-indicator mb24">
            <span id="remaining2">
              <template v-if="remaining2 !== null">
                {{ remaining2 }}
              </template>
              <template v-else>
                00:00
              </template>
            </span> / {{ timeLimit2 }}
          </div>

          <div class="progress mb40">
            <div class="progress-bar" role="progressbar" :style="{width: progress}" aria-valuemin="0" aria-valuemax="100"></div>
          </div>

          <div class="time-indicator small mb24">
            <span id="remaining">
              <template v-if="remaining !== null">
                {{ remaining }}
              </template>
              <template v-else>
                00:00
              </template>
            </span> / {{ timeLimit }}
          </div>

          <div class="media-button mb40">
            <div class="bm-col">
              <button class="btn btn-media" type="button" :disabled="isTimerSaved === false || state == 'start'" @click="playCounter()">
                <span class="icon-ico-play"></span>
              </button>
            </div>
            <div class="bm-col">
              <button class="btn btn-media" type="button" :disabled="isTimerSaved === false || state == 'pause' || state == 'stop' " @click="pauseCounter()">
                <span class="icon-ico-pause"></span>
              </button>
            </div>
            <div class="bm-col">
              <button class="btn btn-media" type="button" :disabled="isTimerSaved === false || state == 'stop' || state == 'pause'" @click="stopCounter()">
                <span class="icon-ico-stop"></span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import Swal from "sweetalert2";

export default {
  name: "ModeratedCaucus",
  props: {
    room: {
      type: Object,
      default: () => {
        return {}
      } 
    }
  },
  data() {
    return {
      durationTime: null,
      speakerTime: null,
      topic: null,
      durHour: 1,
      durMinute: 0,
      durSecond: 0,
      minute: 1,
      second: 0,
      isTimerSaved: false,
      remaining: null,
      remaining2: null,
      counter: null,
      counter2: null,
      participants: [],
      speakers: [],
      activeSpeaker: null,
      state: null,
      startDebate: false
    }
  },
  watch: {
    room() {
      this.getParticipants();
      this.getSpeakers();
      this.getTimer();
    },
    durationTime(newVal) {
      const time = newVal.split(':');
      this.durHour = Number(time[0])
      this.durMinute = Number(time[1])
      this.durSecond = Number(time[2])
    },
    speakerTime(newVal) {
      const time = newVal.split(':');
      this.minute = Number(time[0])
      this.second = Number(time[1])
    }
  },
  computed: {
    timeLimit() {
      return moment().minute(this.minute).second(this.second).format('mm:ss')
    },
    progress() {
      if (this.remaining !== null && this.timeLimit !== null) {
        const currentMinute = moment(this.remaining, 'mm:ss').minute();
        const currentSecond = moment(this.remaining, 'mm:ss').second();
        const current = (currentMinute * 60) + currentSecond

        const endMinute = moment(this.timeLimit, 'mm:ss').minute();
        const endSecond = moment(this.timeLimit, 'mm:ss').second();
        const end = (endMinute * 60) + endSecond

        const progress = current / end * 100

        if (progress >= 100) {
          this.stopCounter()
        }

        return `${progress}%`
      } else {
        return `0%`
      }
    },
    timeLimit2() {
      return moment().hour(this.durHour).minute(this.durMinute).second(this.durSecond).format('HH:mm:ss')
    },
    progress2() {
      if (this.remaining2 !== null && this.timeLimit2 !== null) {
        const currentHour = moment(this.remaining2, 'HH:mm:ss').hour();
        const currentMinute = moment(this.remaining2, 'HH:mm:ss').minute();
        const currentSecond = moment(this.remaining2, 'HH:mm:ss').second();
        const current = (currentHour * 60 * 60) + (currentMinute * 60) + currentSecond

        const endHour = moment(this.timeLimit2, 'HH:mm:ss').hour();
        const endMinute = moment(this.timeLimit2, 'HH:mm:ss').minute();
        const endSecond = moment(this.timeLimit2, 'HH:mm:ss').second();
        const end = (endHour * 60 * 60) + (endMinute * 60) + endSecond

        const progress = current / end * 100

        return `${progress}%`
      } else {
        return `0%`
      }
    }
  },
  methods: {
    increment(type) {
      if (type == 'durHour') {
        this.durHour += 1;
      } else if (type == 'durMinute' && this.durMinute <= 59) {
        this.durMinute += 1;
      } else if (type == 'durSecond' && this.durSecond <= 59){
        this.durSecond += 1;
      } else if (type == 'minute' && this.minute <= 59) {
        this.minute += 1;
      } else if (type == 'second' && this.second <= 59){
        this.second += 1;
      }
    },
    decrement(type) {
      if (type == 'durHour' && this.durHour > 0) {
        this.durHour -= 1;
      } else if (type == 'durMinute' && this.durMinute > 0) {
        this.durMinute -= 1;
      } else if (type == 'durSecond' && this.durSecond > 0){
        this.durSecond -= 1;
      } else if (type == 'minute' && this.minute > 0) {
        this.minute -= 1;
      } else if (type == 'second' && this.second > 0) {
        this.second -= 1;
      }
    },
    getTimer() {
      this.$axios.get(`/master/simulation/${this.room.id}/mc_show_timer`)
        .then(response => {
          this.isTimerSaved = true
          this.state = 'pause'
          this.topic = response.data.data.topic
          this.durHour = response.data.data.duration_hours
          this.durMinute = response.data.data.duration_minutes
          this.durSecond = response.data.data.duration_seconds
          this.minute = response.data.data.speaker_minutes
          this.second = response.data.data.speaker_seconds

          const durHour = this.durHour < 10 ? `0${this.durHour}` : `${this.durHour}`
          const durMinute = this.durMinute < 10 ? `0${this.durMinute}` : `${this.durMinute}`
          const durSecond = this.durSecond < 10 ? `0${this.durSecond}` : `${this.durSecond}`
          this.durationTime = `${durHour}:${durMinute}:${durSecond}`

          const minute = this.minute < 10 ? `0${this.minute}` : `${this.minute}`
          const second = this.second < 10 ? `0${this.second}` : `${this.second}`
          this.speakerTime = `${minute}:${second}`
        })
        .catch(error => {
          console.error(error.response.data)
          this.isTimerSaved = false
        })
    },
    setTimer() {
      this.$axios.post(`/master/simulation/${this.room.id}/mc_set_time`, {
        topic: this.topic,
        duration_hours: this.durHour,
        duration_minutes: this.durMinute,
        duration_seconds: this.durSecond,
        speaker_minutes: this.minute,
        speaker_seconds: this.second
      }).then(response => {
          this.isTimerSaved = true
          this.state = 'pause'
          Swal.fire("Success", response.data.message, "success");
        })
    },
    resetTimer () {
      this.$axios.post(`/master/simulation/${this.room.id}/mc_set_time`, {
        topic: this.topic,
        duration_hours: 1,
        duration_minutes: 0,
        duration_seconds: 0,
        speaker_minutes: 1,
        speaker_seconds: 0
      }).then(() => {
          this.isTimerSaved = false
          this.durHour = 1;
          this.durMinute = 0;
          this.durSecond = 0;
          this.minute = 1
          this.second = 0
          this.durationTime = null;
          this.speakerTime = null;
          this.state = null
          clearInterval(this.counter);
          clearInterval(this.counter2);
          this.remaining = null;
          this.remaining2 = null;

          // this.$emit('setCurrent', {
          //   tab: "Moderated Caucus",
          //   title: "",
          //   country: "",
          //   flag: "",
          //   type: "reset",
          //   duration_hours: this.durHour,
          //   duration_minutes: this.durMinute,
          //   duration_seconds: this.durSecond,
          //   minutes: this.minute,
          //   seconds: this.second,
          //   remaining_duration_hours: this.remaining2 !== null ? moment(this.remaining2, 'HH:mm:ss').hour() : 0,
          //   remaining_duration_minutes: this.remaining2 !== null ? moment(this.remaining2, 'HH:mm:ss').minute() : 0,
          //   remaining_duration_seconds: this.remaining2 !== null ? moment(this.remaining2, 'HH:mm:ss').second() : 0,
          //   remaining_minutes: this.remaining !== null ? moment(this.remaining, 'mm:ss').minute() : 0,
          //   remaining_seconds: this.remaining !== null ? moment(this.remaining, 'mm:ss').second() : 0,
          // })
        })
    },
    getParticipants() {
      this.$axios.get(`/master/simulation/${this.room.id}/mc_list_participant`)
        .then(response => {
          this.participants = response.data.data
        })
    },
    async setActiveSpeaker(speaker) {
      if (this.state == 'start') {
        await this.$axios.post(`/master/simulation/${this.room.id}/ss_stop_timer`, {
          _method: 'PATCH',
          ssl_id: this.activeSpeaker.id,
        }).then(response => {
            console.log(response.data)
          })
      }

      this.activeSpeaker = speaker;
      this.state = 'pause'
      this.remaining = null
      clearInterval(this.counter)
    },
    async getSpeakers() {
      await this.$axios.get(`/master/simulation/${this.room.id}/mc_list_speaker`)
        .then(response => {
          this.speakers = response.data.data
        })
    },
    async addSpeaker(participant) {
      const index = this.speakers.findIndex(speaker => speaker.name == participant.name)
      if (index < 0) {
        this.$axios.post(`/master/simulation/${this.room.id}/mc_add_speaker`, {
          participant_id: participant.id,
          index: this.speakers.length,
        })
          .then(async () => {
            await this.getSpeakers();
          })
      }
    },
    async changeSpeaker(step) {
      if (this.activeSpeaker) {
        if (step > 0) { // next or down
          await this.$axios.post(`/master/simulation/${this.room.id}/mc_down_speaker`, {
            _method: "PATCH",
            id: this.activeSpeaker.id,
          })
          .then(async () => {
            await this.getSpeakers();
          })
        } else { // previous or up
          await this.$axios.post(`/master/simulation/${this.room.id}/mc_up_speaker`, {
            _method: "PATCH",
            id: this.activeSpeaker.id,
          })
          .then(async () => {
            await this.getSpeakers();
          })
        }
      }
    },
    async deleteSpeaker() {        
      if (this.activeSpeaker) {
        await this.$axios.post(`/master/simulation/${this.room.id}/mc_remove_speaker`, {
          _method: 'DELETE',
          ssl_id: this.activeSpeaker.id,
        })
          .then(async () => {
            await this.getSpeakers();
            this.activeSpeaker = null;
          })
        // this.resetTimer()
        this.$emit('setCurrent', {
          tab: "Moderated Caucus",
          title: "",
          country: "",
          flag: "",
          type: "reset",
          duration_hours: this.durHour,
          duration_minutes: this.durMinute,
          duration_seconds: this.durSecond,
          minutes: this.minute,
          seconds: this.second,
          remaining_duration_hours: this.remaining2 !== null ? moment(this.remaining2, 'HH:mm:ss').hour() : 0,
          remaining_duration_minutes: this.remaining2 !== null ? moment(this.remaining2, 'HH:mm:ss').minute() : 0,
          remaining_duration_seconds: this.remaining2 !== null ? moment(this.remaining2, 'HH:mm:ss').second() : 0,
          remaining_minutes: this.remaining !== null ? moment(this.remaining, 'mm:ss').minute() : 0,
          remaining_seconds: this.remaining !== null ? moment(this.remaining, 'mm:ss').second() : 0,
        })
      }
    },
    clearSpeaker() {
      this.speakers = []
      this.activeSpeaker = null
      this.$axios.delete(`/master/simulation/${this.room.id}/mc_clear_speaker`)
        .then(response => {
          console.log("clear speaker ", response.data)
        })
      this.resetTimer()
    },
    playCounter() {
      this.state = 'start'
      let count = this.remaining !== null ? this.remaining : moment(this.timeLimit, 'mm:ss').minute(0).second(0)
      if (moment(count, 'mm:ss').isSameOrBefore(moment(this.timeLimit, 'mm:ss'))) {
        this.$axios.post(`/master/simulation/${this.room.id}/mc_start_timer`, {
          _method: 'PATCH',
          ssl_id: this.activeSpeaker.id,
        }).then(response => {
          console.log(response.data)
        })
      }
      let count2 = this.remaining2 !== null ? this.remaining2 : moment(this.timeLimit2, 'HH:mm:ss').hour(0).minute(0).second(0)
      this.counter2 = setInterval(() => {
        if (moment(count2, 'HH:mm:ss').isSameOrBefore(moment(this.timeLimit2, 'HH:mm:ss'))) {
          count2 =  moment(count2, 'HH:mm:ss').add(1, 'second').format('HH:mm:ss')
          this.remaining2 = count2;
        }
      }, 1000)
      this.counter = setInterval(() => {
        if (moment(count, 'mm:ss').isSameOrBefore(moment(this.timeLimit, 'mm:ss'))) {
          count =  moment(count, 'mm:ss').add(1, 'second').format('mm:ss')
          this.remaining = count;
        }
      }, 1000)
      this.$emit('setCurrent', {
        tab: "Moderated Caucus",
        title: this.topic,
        country: this.activeSpeaker.name,
        flag: this.activeSpeaker.flag,
        type: "start",
        duration_hours: this.durHour,
        duration_minutes: this.durMinute,
        duration_seconds: this.durSecond,
        minutes: this.minute,
        seconds: this.second,
        remaining_duration_hours: this.remaining2 !== null ? moment(this.remaining2, 'HH:mm:ss').hour() : 0,
        remaining_duration_minutes: this.remaining2 !== null ? moment(this.remaining2, 'HH:mm:ss').minute() : 0,
        remaining_duration_seconds: this.remaining2 !== null ? moment(this.remaining2, 'HH:mm:ss').second() : 0,
        remaining_minutes: this.remaining !== null ? moment(this.remaining, 'mm:ss').minute() : 0,
        remaining_seconds: this.remaining !== null ? moment(this.remaining, 'mm:ss').second() : 0,
      })
    },
    pauseCounter() {
      this.state = 'pause'
      clearInterval(this.counter)
      clearInterval(this.counter2)
      this.$axios.post(`/master/simulation/${this.room.id}/mc_pause_timer`, {
        _method: 'PATCH',
        ssl_id: this.activeSpeaker.id,
      }).then(response => {
          console.log(response.data)
        })
      this.$emit('setCurrent', {
        tab: "Moderated Caucus",
        title: this.topic,
        country: this.activeSpeaker.name,
        flag: this.activeSpeaker.flag,
        type: "pause",
        duration_hours: this.durHour,
        duration_minutes: this.durMinute,
        duration_seconds: this.durSecond,
        minutes: this.minute,
        seconds: this.second,
        remaining_duration_hours: moment(this.remaining2, 'HH:mm:ss').hour(),
        remaining_duration_minutes: moment(this.remaining2, 'HH:mm:ss').minute(),
        remaining_duration_seconds: moment(this.remaining2, 'HH:mm:ss').second(),
        remaining_minutes: moment(this.remaining, 'mm:ss').minute(),
        remaining_seconds: moment(this.remaining, 'mm:ss').second(),
      })
    },
    stopCounter() {
      this.state = 'stop'
      clearInterval(this.counter)
      clearInterval(this.counter2)
      this.remaining = this.timeLimit
      this.$axios.post(`/master/simulation/${this.room.id}/mc_stop_timer`, {
        _method: 'PATCH',
        ssl_id: this.activeSpeaker.id,
      }).then(response => {
          console.log(response.data)
        })
      this.$emit('setCurrent', {
        tab: "Moderated Caucus",
        title: this.topic,
        country: this.activeSpeaker.name,
        flag: this.activeSpeaker.flag,
        type: "stop",
        duration_hours: this.durHour,
        duration_minutes: this.durMinute,
        duration_seconds: this.durSecond,
        minutes: this.minute,
        seconds: this.second,
        remaining_duration_hours: moment(this.remaining2, 'HH:mm:ss').hour(),
        remaining_duration_minutes: moment(this.remaining2, 'HH:mm:ss').minute(),
        remaining_duration_seconds: moment(this.remaining2, 'HH:mm:ss').second(),
        remaining_minutes: 0,
        remaining_seconds: 0,
      })
    }
  }
}
</script>

<style scoped>
button:disabled {
  pointer-events: auto !important;
  cursor: not-allowed !important;
}
input:read-only {
  background-color: white !important;
}
</style>