<template>
  <div class="box-table mb50">
    <section class="show-item">
      <div class="container mt-4">
        <div class="row">
          <div class="col-lg-6 col-sm-6">
            <div class="create-form">
              <label class="label-form">Show Entries</label>
              <select class="form-select black" name="per_page" id="per_page" v-model="perPage">
                <option v-for="(page, index) in pageOptions" :key="index" :value="page">{{ page }}</option>
              </select>
            </div>
          </div>
          <div class="col-lg-6 col-sm-6">
            <div class="create-form">
              <label class="label-form">Search</label>
              <input type="text" name="search" id="search-participant" class="form-control" placeholder="Search..." v-model="filter">
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- Table -->
    <b-table
      outlined
      ref="table"
      :items="fetchParticipants"
      :fields="fields"
      responsive="sm"
      :per-page="perPage"
      :current-page="currentPage"
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
      :filter="filter"
      :empty-text="'There are no records to show'"
      :empty-filtered-text="'There are no records matching your request'"
      class="table-manage"
    >
      <!-- A custom formatted header cell for field 'name' -->
      <template #head(id)>
        <div class="form-check">
          <input class="form-check-input" type="checkbox" v-model="selectedAll" name="selected-participants">
          <label class="form-check-label"></label>
        </div>
      </template>

      <!-- Id -->
      <template v-slot:cell(id)="data">
        <div class="form-check">
          <input class="form-check-input" type="checkbox" :value="data.item.id" v-model="selected" name="selected-participants">
          <label class="form-check-label"></label>
        </div>
      </template>
      <!-- status -->
      <template v-slot:cell(status_participant)="data">
        <div class="box-status ongoing" v-if="data.item.status_participant == 'Approved'">{{ data.item.status_participant }}</div>
        <div class="box-status start" v-if="data.item.status_participant == 'Waiting Delegate'">{{ data.item.status_participant }}</div>
        <div class="box-status start" v-if="data.item.status_participant == 'Rejected'">Waiting Delegate</div>
        <div class="box-status aprroval" v-if="data.item.status_participant == 'Waiting Approval'">{{ data.item.status_participant }}</div>
      </template>
      <!-- Aciton -->
      <template v-slot:cell(action)="data">
        <div class="box-btn-action">
          <template v-if="roomDetail.status_simulation == 1">
            <template v-if="data.item.status_participant == 'Waiting Approval'">
              <button type="button" class="btn btn-action mx-1" @click="approveId = data.item.id">
                <span class="icon-ico-check"></span>
              </button>
              <button type="button" class="btn btn-action mx-1" @click="rejectId = data.item.id">
                <span class="icon-ico-close"></span>
              </button>
              <button type="button" class="btn btn-action mx-1" @click="deletedId = data.item.id">
                <span class="icon-ico-delete"></span>
              </button>
            </template>
            <template v-if="data.item.status_participant == 'Waiting Delegate' || data.item.status_participant == 'Rejected'">
              <button type="button" class="btn btn-action mx-1" @click="deletedId = data.item.id">
                <span class="icon-ico-delete"></span>
              </button>
            </template>
            <!-- <template v-if="data.item.status_participant == 'Waiting Delegate' || data.item.status_participant == 'Rejected'">
              <button type="button" class="btn btn-action mx-1 for-delete" @click="confirmDelegate(data.item.id)">
                <span class="icon-ico-plane"></span>
              </button>
            </template> -->
          </template>
        </div>
      </template>
    </b-table>
    <div class="filter-row">
      <div class="ft-filter">Showing {{ startRow }} - {{ untilRow }} of {{ totalRows }} list</div>
      <div class="button-filter">
        <ul class="pagination pagination-rounded mb-0">
          <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="perPage"></b-pagination>
        </ul>
      </div>
    </div>
    <div class="button-generate">
      <div class="bg-left">
        <button :disabled="selected.length == 0" class="btn btn-orange block-mobile mr-2" type="button" @click="showDelegatePopup = true">Delegate</button>
        <button :disabled="!canSubmit" class="btn btn-orange block-mobile" type="button" @click="showSubmitPopup = true">Submit</button>
      </div>
      <div class="gb-right">
        <div class="reset-gen">
          <button class="btn btn-grey" type="button" @click="confirmReset">Reset</button>
        </div>
        <div style="display: block; float: right;">
          <button class="btn btn-bordered-blue" type="button" @click="showGeneratePopup = true">Generate</button>
        </div>
      </div>
    </div>

    <!-- popup confirm submit participant -->
    <div class="popup-overlay" :class="{ show: showSubmitPopup}" v-if="showSubmitPopup">
      <div class="inner-delete" :class="{ popup: !finishSubmit && !failedSubmit}">
        <div class="title-info">Confirmation</div>
        <p>Are you sure want to submit this participant room ?</p>
        <div class="row g-2">
          <div class="col-6">
            <button type="button" class="btn btn-delete w-100" id="cancel-start-simulation" @click="showSubmitPopup = false">Cancel</button>
          </div>
          <div class="col-6">
            <button type="button" class="btn btn-orange w-100" id="submit-start-simulation" @click="submit">Yes</button>
          </div>
        </div>
      </div>
      <div class="box-thanks" :class="{ show: finishSubmit }">
        <img src="/img/check.svg" class="img-fluid">
        <div class="ttl-oke">Submit Participant Successfully</div>
        <p></p>
        <button type="button" class="btn btn-orange btn-radius for-ok" id="ok" @click="[showSubmitPopup = false, finishSubmit = false]">Ok</button>
      </div>
    </div>

    <!-- popup confirm reset participant -->
    <div class="popup-overlay" :class="{ show: showResetPopup}" v-if="showResetPopup">
      <div class="inner-delete" :class="{ popup: !finishReset && !failedReset}">
        <div class="title-info">Confirmation</div>
        <p>Are you sure want to reset selected participant ?</p>
        <div class="row g-2">
          <div class="col-6">
            <button type="button" class="btn btn-delete w-100" id="cancel-reset" @click="showResetPopup = false">Cancel</button>
          </div>
          <div class="col-6">
            <button type="button" class="btn btn-orange w-100" id="submit-reset" @click="reset">Yes</button>
          </div>
        </div>
      </div>
      <div class="box-thanks" :class="{ show: finishReset }">
        <img src="/img/check.svg" class="img-fluid">
        <div class="ttl-oke">Reset Participant Successfully</div>
        <p></p>
        <button type="button" class="btn btn-orange btn-radius for-ok" id="ok" @click="[showResetPopup = false, finishReset = false]">Ok</button>
      </div>
      <div class="box-thanks" :class="{ show: failedReset }">
        <img src="/img/cancel.png" style="width: 50px; margin-bottom: 20px">
        <div class="ttl-oke">You must select at least one participant to reset</div>
        <p></p>
        <button type="button" class="btn btn-orange btn-radius for-ok" id="ok" @click="[showResetPopup = false, failedReset = false]">Ok</button>
      </div>
    </div>

    <!-- popup confirm generate participant -->
    <div class="popup-overlay" :class="{ show: showGeneratePopup}" v-if="showGeneratePopup">
      <div class="inner-delete" :class="{ popup: !finishGenerate }">
        <div class="title-info">Confirmation</div>
        <p>Are you sure want to generate this participant room?</p>
        <div class="row g-2">
          <div class="col-6">
            <button type="button" class="btn btn-delete w-100" id="cancel-reset" @click="showGeneratePopup = false">Cancel</button>
          </div>
          <div class="col-6">
            <button type="button" class="btn btn-orange w-100" id="submit-reset" @click="generate">Yes</button>
          </div>
        </div>
      </div>
      <div class="box-thanks" :class="{ show: finishGenerate }">
        <img src="/img/check.svg" class="img-fluid">
        <div class="ttl-oke">Generate Participant Successfully</div>
        <p></p>
        <button type="button" class="btn btn-orange btn-radius for-ok" id="ok" @click="[showGeneratePopup = false, finishGenerate = false]">Ok</button>
      </div>
    </div>

    <!-- popup confirm reject participant -->
    <div class="popup-overlay" :class="{ show: rejectId}" v-if="rejectId">
      <div class="inner-delete" :class="{ popup: !finishReject}">
        <div class="title-info">Confirmation</div>
        <p>Are you sure want to reject this participant ?</p>
        <div class="row g-2">
          <div class="col-6">
            <button type="button" class="btn btn-delete w-100" id="cancel-start-simulation" @click="rejectId = null">Cancel</button>
          </div>
          <div class="col-6">
            <button type="button" class="btn btn-orange w-100" id="submit-start-simulation" @click="reject">Yes</button>
          </div>
        </div>
      </div>
      <div class="box-thanks" :class="{ show: finishReject }">
        <img src="/img/check.svg" class="img-fluid">
        <div class="ttl-oke">Reject Participant Successfully</div>
        <p></p>
        <button type="button" class="btn btn-orange btn-radius for-ok" id="ok" @click="[rejectId = null, finishReject = false]">Ok</button>
      </div>
    </div>

    <!-- popup confirm approve participant -->
    <div class="popup-overlay" :class="{ show: approveId}" v-if="approveId">
      <div class="inner-delete" :class="{ popup: !finishApprove}">
        <div class="title-info">Confirmation</div>
        <p>Are you sure want to approve this participant ?</p>
        <div class="row g-2">
          <div class="col-6">
            <button type="button" class="btn btn-delete w-100" id="cancel-start-simulation" @click="approveId = null">Cancel</button>
          </div>
          <div class="col-6">
            <button type="button" class="btn btn-orange w-100" id="submit-start-simulation" @click="approve">Yes</button>
          </div>
        </div>
      </div>
      <div class="box-thanks" :class="{ show: finishApprove }">
        <img src="/img/check.svg" class="img-fluid">
        <div class="ttl-oke">Approve Participant Successfully</div>
        <p></p>
        <button type="button" class="btn btn-orange btn-radius for-ok" id="ok" @click="[approveId = null, finishApprove = false]">Ok</button>
      </div>
    </div>

    <!-- popup confirm delete participant -->
    <div class="popup-overlay" :class="{ show: deletedId}" v-if="deletedId">
      <div class="inner-delete" :class="{ popup: !finishDelete}">
        <div class="title-info">Confirmation</div>
        <p>Are you sure want to delete this participant ?</p>
        <div class="row g-2">
          <div class="col-6">
            <button type="button" class="btn btn-delete w-100" id="cancel-start-simulation" @click="deletedId = null">Cancel</button>
          </div>
          <div class="col-6">
            <button type="button" class="btn btn-orange w-100" id="submit-start-simulation" @click="deleteParticipant">Yes</button>
          </div>
        </div>
      </div>
      <div class="box-thanks" :class="{ show: finishDelete }">
        <img src="/img/check.svg" class="img-fluid">
        <div class="ttl-oke">Delete Participant Successfully</div>
        <p></p>
        <button type="button" class="btn btn-orange btn-radius for-ok" id="ok" @click="[deletedId = null, finishDelete = false]">Ok</button>
      </div>
    </div>

    <!-- popup confirm delegate participant -->
    <div class="popup-overlay" :class="{ show: showDelegatePopup }" v-if="showDelegatePopup">
      <div class="inner-delete" :class="{ popup: !finishDelegate }">
        <form @submit.prevent="bulkDelegate" id="form-join-room">
          <div class="title-info mb-3">Simulation Registration</div>
          <div class="label-popup mb-2">Choose Country</div>
          <select class="form-select mb-3" id="join-country" v-model="delegateForm.participant_id" required>
            <option :value="null">Country</option>
            <option v-for="(country, i) in countries" :key="i" :value="country.id">{{ country.name }}</option>
          </select>
          <div class="row g-2">
            <div class="col-6">
              <button type="button" class="btn btn-delete  btn-radius w-100" id="cancel-join" @click="postDelegate">Cancel</button>
            </div>
            <div class="col-6">
              <button type="submit" class="btn btn-orange btn-radius for-submit w-100" id="submit-join">Submit</button>
            </div>
          </div>
        </form>
      </div>
      <div class="box-thanks" :class="{ show: finishDelegate }">
        <img src="/img/check.svg" class="img-fluid">
        <div class="ttl-oke">Registration Completed Successfully</div>
        <p></p>
        <button type="button" class="btn btn-orange btn-radius for-ok" id="ok" @click="postDelegate">Ok</button>
      </div>
    </div>

  </div>
</template>

<script>
export default {
  name: "ParticipantList",
  props: {
    room: {
      type: Object,
      default: () => {
        return {}
      } 
    }
  },
  data() {
    return {
      roomDetail: {},
      totalRows: 0,
      currentPage: 1,
      perPage: 10,
      filter: null,
      sortBy: "created_at",
      sortDesc: false,
      pageOptions: [10, 25, 50, 100],
      fields: [
        { key: "id", label: "", sortable: false },
        { key: "nim", label: "NIM", sortable: true },
        { key: "name", label: "NAME", sortable: true },
        { key: "class", label: "CLASS", sortable: true },
        { key: "country", label: "COUNTRY", sortable: true },
        { key: "join_date", label: "JOIN DATE", sortable: true },
        { key: "status_participant", label: "STATUS", sortable: true },
        'ACTION'
      ],
      dataList: [],
      canSubmit: false,
      countries: [],
      showDelegatePopup: false,
      finishDelegate: false,
      delegateForm: {
        student_id: null,
        participant_id: null,
      },
      rejectId: null,
      finishReject: false,
      approveId: null,
      finishApprove: false,
      deletedId: null,
      finishDelete: false,
      selected: [],
      selectedAll: false,
      showSubmitPopup: false,
      failedSubmit: false,
      finishSubmit: false,
      showResetPopup: false,
      failedReset: false,
      finishReset: false,
      showGeneratePopup: false,
      finishGenerate: false,
    }
  },
  computed: {
    startRow() {
      if (this.currentPage == 1) {
        return 1
      } else {
        return (this.perPage * (this.currentPage - 1 )) + 1
      }
    },
    untilRow() {
      if ((this.currentPage * this.perPage) <= this.totalRows) {
        return this.currentPage * this.perPage
      } else {
        return this.totalRows
      }
    }
  },
  watch: {
    room() {
      this.$refs.table.refresh();
      this.getDetailRoom();
    },
    selectedAll(newVal) {
      if (newVal) {
        this.dataList.forEach(item => {
          if (item.status_participant == 'Waiting Delegate' || item.status_participant == 'Rejected') {
            this.selected.push(item.id) 
          }
        })
      } else {
        this.selected = []
      }
    }
  },
  methods: {
    async fetchParticipants(ctx) {
      if (this.room.id === undefined) {
        this.totalRows = 0;
        return []
      } else {
        const promise = this.$axios.get(`/master/simulation/participant`, {
          params: {
            page: ctx.currentPage,
            per_page: ctx.perPage,
            search: ctx.filter,
            sortBy: ctx.sortBy,
            sortType: (ctx.sortDesc)? 'asc' : 'desc',
            roomId: this.room.id,
          }
        })

        // Must return a promise that resolves to an array of items
        return promise.then(response => {
          // Pluck the array of items off our axios response
          const items = response.data.data.data
          this.totalRows = response.data.data.meta.total
          this.canSubmit = response.data.data.meta.canSubmit
          this.dataList = items || []
          // Must return an array of items or an empty array if an error occurred
          return items || []
        })
      }
    },
    getDetailRoom() {
      this.$axios.get(`/master/room/${this.room.id}`)
        .then(response => {
          this.roomDetail = response.data.data
          this.countries = this.roomDetail.participants
        })
    },
    approve() {
      this.$axios.post(`/master/simulation/participant/approve_country`, {
        id: this.approveId,
      })
        .then(() => {
          this.finishApprove = true;
          this.$refs.table.refresh();
        })
    },
    reject() {
      this.$axios.post(`/master/simulation/participant/reject_country`, {
        id: this.rejectId,
      })
        .then(() => {
          this.finishReject = true;
          this.$refs.table.refresh();
        })
    },
    deleteParticipant() {
      this.$axios.post(`/master/simulation/participant/reject`, {
        participant_id: this.deletedId,
      })
        .then(() => {
          this.finishDelete = true;
          this.$refs.table.refresh();
        })
    },
    confirmDelegate(studentId) {
      this.showDelegatePopup = true
      this.delegateForm.student_id = studentId
    },
    delegate() {
      this.$axios.post(`/master/simulation/participant/delegate`, {
        id: this.delegateForm.student_id,
        participant_id: this.delegateForm.participant_id,
      })
        .then(() => {
          this.finishDelegate = true;
          this.$refs.table.refresh();
        })
    },
    bulkDelegate() {
      this.$axios.post(`/master/simulation/participant/bulk_delegate`, {
        participant_student_ids: this.selected,
        participant_id: this.delegateForm.participant_id,
      })
        .then(() => {
          this.finishDelegate = true;
          this.selected = []
          this.$refs.table.refresh();
        })
    },
    postDelegate() {
      this.showDelegatePopup = false
      this.finishDelegate = false
      this.delegateForm = {
        student_id: null,
        participant_id: null
      }
      this.selected = []
    },
    submit() {
      this.$axios.post(`/master/simulation/participant/submit`, {
        room_id: this.room.id
      })
        .then(() => {
          this.finishSubmit = true;
          this.getDetailRoom();
          this.$refs.table.refresh();
        })
    },
    confirmReset() {
      this.showResetPopup = true
      if (this.selected.length == 0) {
        this.failedReset = true
      }
    },
    reset() {
      this.$axios.post(`/master/simulation/participant/reset`, {
        participant_student_ids: this.selected
      })
        .then(() => {
          this.finishReset = true;
          this.selected = []
          this.$refs.table.refresh();
        })
    },
    generate() {
      this.$axios.post(`/master/simulation/participant/generate`, {
        room_id: this.room.id
      })
        .then(() => {
          this.finishGenerate = true;
          this.$refs.table.refresh();
        })
    }
  }
}
</script>

<style type="css" scoped>
.filter-row {
  padding: 0px 24px 0px 24px
}
.mr-2 {
  margin-right: 6px;
}
</style>