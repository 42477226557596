<template>
  <div class="inner-tabs-simulation">
    <section class="show-item">
      <div class="container mt-4">
        <div class="row">
          <div class="col-lg-6 col-sm-6">
            <div class="create-form">
              <label class="label-form">Show Entries</label>
              <select class="form-select black" name="per_page" id="per_page" v-model="perPage">
                <option v-for="(page, index) in pageOptions" :key="index" :value="page">{{ page }}</option>
              </select>
            </div>
          </div>
          <div class="col-lg-6 col-sm-6">
            <div class="create-form">
              <label class="label-form">Search</label>
              <input type="text" name="search" id="search-participant" class="form-control" placeholder="Search..." v-model="filter">
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- Table -->
    <b-table
      outlined
      ref="table"
      :items="fetchDraft"
      :fields="fields"
      responsive="sm"
      :per-page="perPage"
      :current-page="currentPage"
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
      :filter="filter"
      :empty-text="'There are no records to show'"
      :empty-filtered-text="'There are no records matching your request'"
      class="table-manage"
    >
      <!-- status -->
      <template v-slot:cell(status_text)="data">
        <div class="box-status ongoing" v-if="data.item.status_text == 'Approved'">{{ data.item.status_text }}</div>
        <div class="box-status start" v-if="data.item.status_text == 'Rejected'">{{ data.item.status_text }}</div>
        <div class="box-status aprroval" v-if="data.item.status_text == 'Submitted'">{{ data.item.status_text }}</div>
      </template>
      <!-- Aciton -->
      <template v-slot:cell(action)="data">
        <div class="box-btn-action">
          <a :href="data.item.paper_link" type="button" class="btn btn-icon" target="_blank">
            <span class="icon-ico-download"></span>
            Download
          </a>
        </div>
      </template>
    </b-table>
    <div class="filter-row">
      <div class="ft-filter">Showing {{ startRow }} - {{ untilRow }} of {{ totalRows }} list</div>
      <div class="button-filter">
        <ul class="pagination pagination-rounded mb-0">
          <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="perPage"></b-pagination>
        </ul>
      </div>
    </div>

    <div class="row mt-5">
      <div class="col-lg-6 col-sm-6">
        <div class="set-time mb-3">
          <div class="set-text-time">Voting On</div>
          <div class="set-time-form">
            <select class="form-select no-radius" v-model="votingOn" @change="getOutCome">
              <option :value="null" selected>Alliance</option>
              <option v-for="(alliance, index) in aliances" :key="index" :value="alliance">
                {{ alliance.alliance_name }}
              </option>
            </select>
          </div>
        </div>
        <div class="box-candidate mb-3 mh-1">
          <div class="bc-head justify-content-start">
            <div class="sl">Country List</div>
          </div>
          <div class="bc-content">
            <ul class="nav nav-tabs" id="speakerTab" role="tablist">
              <template v-if="detailAlliance !== null">
                <template v-for="(country, index) in detailAlliance.country_list">
                    <li class="nav-item" role="presentation" :key="index">
                      <a href="#" class="nav-link">
                        {{ country }}
                      </a>
                    </li>
                </template>
              </template>
            </ul>
          </div>
        </div>

        <div class="for-btn-action" v-if="detailAlliance !== null">
          <div class="inner-fba">
            <div class="col-fba">
              <div class="button-split" v-if="detailAlliance.voting_status == 'not-start'">
                <button class="btn btn-action-speaker" type="button" @click="popupStart = true">Start</button>
              </div>
              <div class="button-split" v-if="detailAlliance.voting_status == 'on-going'">
                <button class="btn btn-action-speaker" type="button" @click="popupEnd = true">Stop</button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-6 col-sm-6">
        <div class="box-candidate mb-3 mh-3">
          <div class="bc-head outcome justify-content-center">
            OUTCOME
          </div>
          <div class="bc-content p25" v-if="outcome !== null">
            <div class="oi-row">
              <div class="oi-1">In Favour</div>
              <div class="mx-2">:</div>
              <div class="oi-2">{{ outcome.in_favour }}</div>
            </div>
            <div class="oi-row">
              <div class="oi-1">Againts</div>
              <div class="mx-2">:</div>
              <div class="oi-2">{{ outcome.againts }}</div>
            </div>
            <div class="oi-row">
              <div class="oi-1">Abstentions</div>
              <div class="mx-2">:</div>
              <div class="oi-2">{{ outcome.absentionts }}</div>
            </div>
            <div class="oi-row">
              <div class="oi-1">Result</div>
              <div class="mx-2">:</div>
              <div class="oi-2">
                <div class="d-flex flex-wrap">
                  <div class="out-status active" v-if="outcome.result === true">Pass</div>
                  <div class="out-status" v-if="outcome.result === false">Failed</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- popup confirm start vote -->
    <div class="popup-overlay" :class="{ show: popupStart}">
      <div class="inner-delete" :class="{ popup: popupStart}">
        <div class="title-info">Confirmation</div>
        <p>Are you sure want to start voting this simulation ?</p>
        <div class="row g-2">
          <div class="col-6">
            <button type="button" class="btn btn-delete w-100" id="cancel-start-simulation" @click="popupStart = false">Cancel</button>
          </div>
          <div class="col-6">
            <button type="button" class="btn btn-orange w-100" id="submit-start-simulation" @click="startVote">Yes</button>
          </div>
        </div>
      </div>
    </div>

    <!-- popup confirm start vote -->
    <div class="popup-overlay" :class="{ show: popupEnd}">
      <div class="inner-delete" :class="{ popup: popupEnd}">
        <div class="title-info">Confirmation</div>
        <p>Are you sure want to stop voting this simulation ?</p>
        <div class="row g-2">
          <div class="col-6">
            <button type="button" class="btn btn-delete w-100" id="cancel-start-simulation" @click="popupEnd = false">Cancel</button>
          </div>
          <div class="col-6">
            <button type="button" class="btn btn-orange w-100" id="submit-start-simulation" @click="stopVote">Yes</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Swal from "sweetalert2";

export default {
  name: "RollCallVote",
  props: {
    room: {
      type: Object,
      default: () => {
        return {}
      } 
    }
  },
  data() {
    return {
      totalRows: 0,
      currentPage: 1,
      perPage: 10,
      filter: null,
      sortBy: "created_at",
      sortDesc: false,
      pageOptions: [10, 25, 50, 100],
      fields: [
        { key: "alliance_name", label: "ALLIANCE", sortable: true },
        { key: "sponsor_country", label: "SPONSORS", sortable: true },
        // { key: "paper", label: "PAPER", sortable: true },
        { key: "status_text", label: "STATUS", sortable: true },
        { key: "upload_date", label: "UPLOAD DATE", sortable: true },
        'ACTION'
      ],
      aliances: [],
      aliances2: [],
      detailAlliance: null,
      votingOn: null,
      outcome: null,
      popupStart: false,
      popupEnd: false
    }
  },
  computed: {
    startRow() {
      if (this.currentPage == 1) {
        return 1
      } else {
        return (this.perPage * (this.currentPage - 1 )) + 1
      }
    },
    untilRow() {
      if ((this.currentPage * this.perPage) <= this.totalRows) {
        return this.currentPage * this.perPage
      } else {
        return this.totalRows
      }
    }
  },
  watch: {
    room() {
      this.$refs.table.refresh();
      this.getAlliance();
    }
  },
  methods: {
    async fetchDraft(ctx) {
      if (this.room.id === undefined) {
        this.totalRows = 0;
        return []
      } else {
        const promise = this.$axios.get(`/master/simulation/${this.room.id}/roll_call_vote_list`, {
          params: {
            page: ctx.currentPage,
            per_page: ctx.perPage,
            search: ctx.filter,
            sortBy: ctx.sortBy,
            sortType: (ctx.sortDesc)? 'asc' : 'desc',
          }
        })

        // Must return a promise that resolves to an array of items
        return promise.then(response => {
          // Pluck the array of items off our axios response
          const items = response.data.data.data
          this.totalRows = response.data.data.meta.total
          // Must return an array of items or an empty array if an error occurred
          return items || []
        })
      }
    },
    async getAlliance() {
      await this.$axios.get(`/master/simulation/${this.room.id}/list_alliance`)
      .then(response => {
          this.aliances2 = response.data.data
        })
      await this.$axios.get(`/master/simulation/${this.room.id}/roll_call_vote_list`, {
        params: {
          page: 1,
          per_page: 10000,
          search: '',
          sortBy: 'name',
          sortType: 'asc',
        }
      })
      .then(response => {
          this.aliances = response.data.data.data
        })
    },
    getOutCome() {
      this.$axios.get(`/master/simulation/${this.room.id}/outcome`, {
        params: {
          assignmentId: this.votingOn.id
        }
      })
      .then(response => {
          this.outcome = response.data.data
        })
      this.detailAlliance = this.aliances2.find((item) => item.id === this.votingOn.id)
    },
    startVote() {
      this.$axios.post(`/master/simulation/${this.room.id}/start_roll_call_vote`, {
        assignment_id: this.detailAlliance.id
      })
      .then( async response => {
          Swal.fire("Success", response.data.message, "success");
          await this.getAlliance()
          this.detailAlliance = this.aliances.find(allliance => allliance.id == this.detailAlliance.id)
          this.popupStart = false
        })
    },
    stopVote() {
      this.$axios.post(`/master/simulation/${this.room.id}/stop_roll_call_vote`, {
        assignment_id: this.detailAlliance.id
      })
      .then( async response => {
          Swal.fire("Success", response.data.message, "success");
          await this.getAlliance()
          this.detailAlliance = this.aliances.find(allliance => allliance.id == this.detailAlliance.id)
          this.popupEnd = false
        })
    }
  }
}
</script>