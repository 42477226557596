<template>
  <div class="inner-tabs-simulation">
    <div id="simulation" class="row align-items-center justify-content-between">
      <div class="col-lg-5 col-sm-6">
        <form @submit.prevent="setTimer()">
          <div class="set-time mb-3">
            <div class="set-text-time">Voting On</div>
            <div class="set-time-form">
              <input type="text" class="form-control no-radius" v-model="voting" :readonly="isTimerSaved" :required="!isTimerSaved">
            </div>
          </div>
          <div class="set-time mb-4">
            <div class="set-text-time"><span v-if="isTimerSaved === false">Set</span> Speaker Time</div>
            <div class="set-time-form">
              <div class="stf-inner">
                <div class="pt-4 mx-3">
                  <date-picker
                    v-model="speakerTime"
                    format="mm:ss"
                    value-type="format"
                    type="time"
                    placeholder="mm:ss"
                    :disabled="isTimerSaved"
                  ></date-picker>
                </div>
                <div>
                  <button type="submit" v-if="isTimerSaved === false" class="btn btn-action-speaker" style="margin-top: 26px">Save</button>
                  <button type="button" v-if="isTimerSaved === true" :disabled="state === null || state == 'start'" class="btn btn-action-speaker" style="margin-top: 26px" @click="resetTimer()">Reset</button>
                </div>
              </div>
            </div>
          </div>
        </form>
        <div class="in-current mb-3">In Favour</div>
        <div class="box-candidate mb-3 mh-2">
          <div class="bc-head justify-content-end">
            <div class="dropdown">
              <button class="btn btn-add-speaker" type="button" id="dropdownMenuButton1"
                data-bs-toggle="dropdown" aria-expanded="false">
                Choose Country <span class="icon-ico-plus"></span>
              </button>
              <ul class="dropdown-menu speaker dropdown-menu-end"
                aria-labelledby="dropdownMenuButton1">
                <template v-for="(participant, index) in participants">
                  <li :key="index">
                    <button type="button" class="dropdown-item" href="#" @click="addInFavourSpeaker(participant)">
                      {{ participant.name }}
                    </button>
                  </li>
                </template>
              </ul>
            </div>
          </div>
          <div class="bc-content">
            <ul class="nav nav-tabs" id="speakerTab" role="tablist">
              <template v-for="(speaker, index) in inFavourSpeakers">
                <li :key="index" class="nav-item" role="presentation">
                  <a 
                    class="nav-link" :id="`speaker-${speaker.id}`" data-bs-toggle="tab"
                    data-bs-target="#usa" type="button" role="tab" aria-controls="usa"
                    :aria-selected="[speaker == activeSpeaker ? true : false]"
                    :class="{active: speaker == activeSpeaker}"
                    @click="setActiveSpeaker(speaker, 'favour')"
                  >
                    {{ speaker.name }}
                  </a>
                </li>
              </template>
            </ul>
          </div>
        </div>
        <div class="for-btn-action">
          <div class="inner-fba">
            <div class="col-fba">
              <div class="button-split">
                <button type="button" class="btn btn-action-speaker" @click="changeInFavourSpeaker(-1)" :disabled="inFavourSpeakers.length == 0">Up</button>
              </div>
              <div class="button-split">
                <button type="button" class="btn btn-action-speaker" @click="changeInFavourSpeaker(1)" :disabled="inFavourSpeakers.length == 0">Down</button>
              </div>
            </div>
            <div class="col-fba">
              <div class="button-split">
                <button type="button" class="btn btn-action-speaker" @click="deleteInFavourSpeaker()" :disabled="inFavourSpeakers.length == 0">Delete</button>
              </div>
              <div class="button-split">
                <button class="btn btn-action-speaker" @click="clearInFavourSpeaker()" :disabled="inFavourSpeakers.length == 0">Clear</button>
              </div>
            </div>
          </div>
        </div>
        <div class="in-current mb-3 mt-5">Againts</div>
        <div class="box-candidate mb-3 mh-2">
          <div class="bc-head justify-content-end">
            <div class="dropdown">
              <button class="btn btn-add-speaker" type="button" id="dropdownMenuButton1"
                data-bs-toggle="dropdown" aria-expanded="false">
                Choose Country <span class="icon-ico-plus"></span>
              </button>
              <ul class="dropdown-menu speaker dropdown-menu-end"
                aria-labelledby="dropdownMenuButton1">
                <template v-for="(participant, index) in participants">
                  <li :key="index">
                    <button type="button" class="dropdown-item" href="#" @click="addAgaintsSpeaker(participant)">
                      {{ participant.name }}
                    </button>
                  </li>
                </template>
              </ul>
            </div>
          </div>
          <div class="bc-content">
            <ul class="nav nav-tabs" id="speakerTab" role="tablist">
              <template v-for="(speaker, index) in againtsSpeakers">
                <li :key="index" class="nav-item" role="presentation">
                  <a 
                    class="nav-link" :id="`speaker-${speaker.id}`" data-bs-toggle="tab"
                    data-bs-target="#usa" type="button" role="tab" aria-controls="usa"
                    :aria-selected="[speaker == activeSpeaker ? true : false]"
                    :class="{active: speaker == activeSpeaker}"
                    @click="setActiveSpeaker(speaker, 'againts')"
                  >
                    {{ speaker.name }}
                  </a>
                </li>
              </template>
            </ul>
          </div>
        </div>
        <div class="for-btn-action">
          <div class="inner-fba">
            <div class="col-fba">
              <div class="button-split">
                <button type="button" class="btn btn-action-speaker" @click="changeAgaintsSpeaker(-1)" :disabled="againtsSpeakers.length == 0">Up</button>
              </div>
              <div class="button-split">
                <button type="button" class="btn btn-action-speaker" @click="changeAgaintsSpeaker(1)" :disabled="againtsSpeakers.length == 0">Down</button>
              </div>
            </div>
            <div class="col-fba">
              <div class="button-split">
                <button type="button" class="btn btn-action-speaker" @click="deleteAgaintsSpeaker()" :disabled="againtsSpeakers.length == 0">Delete</button>
              </div>
              <div class="button-split">
                <button class="btn btn-action-speaker" @click="clearAgaintsSpeaker()" :disabled="againtsSpeakers.length == 0">Clear</button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-lg-5 col-sm-6">
        <template v-if="activeSpeaker">
          <div class="pv-title mb24">Agenda</div>
          <ul class="nav nav-pills justify-content-center mb50" id="pills-voting" role="tablist">
            <li class="nav-item" role="presentation">
              <button class="nav-link" :class="{ active: activeSpeaker.type == 'favour' }" id="pills-home-tab" data-bs-toggle="pill"
                data-bs-target="#pills-home" type="button" role="tab" aria-controls="pills-home"
                aria-selected="true">In Favour</button>
            </li>
            <li class="nav-item" role="presentation">
              <button class="nav-link" :class="{ activeb: activeSpeaker.type == 'againts' }" id="pills-profile-tab" data-bs-toggle="pill"
                data-bs-target="#pills-profile" type="button" role="tab" aria-controls="pills-profile"
                aria-selected="false">Againts</button>
            </li>
          </ul>
          <div class="box-content-right">
            <div class="box-flag">
              <img v-if="activeSpeaker.type === 'ngo'" src="/img/ngo.png" style="max-width: 240px; border: 1px solid" class="img-fluid" alt="Flag">
              <img v-else :src="activeSpeaker.flag" style="max-width: 240px; border: 1px solid" class="img-fluid" alt="Flag">
              <p>{{ activeSpeaker.name }}</p>
            </div>

            <div class="progress mb40">
              <div class="progress-bar" role="progressbar" :style="{width: progress}" aria-valuemin="0" aria-valuemax="100"></div>
            </div>

            <div class="time-indicator mb24">
              <span id="remaining">
                <template v-if="remaining !== null">
                  {{ remaining }}
                </template>
                <template v-else>
                  00:00
                </template>
              </span> / {{ timeLimit }}
            </div>

            <div class="media-button mb40">
              <div class="bm-col">
                <button class="btn btn-media" type="button" :disabled="isTimerSaved === false || state == 'start'" @click="playCounter()">
                  <span class="icon-ico-play"></span>
                </button>
              </div>
              <div class="bm-col">
                <button class="btn btn-media" type="button" :disabled="isTimerSaved === false || state == 'pause' || state == 'stop' " @click="pauseCounter()">
                  <span class="icon-ico-pause"></span>
                </button>
              </div>
              <div class="bm-col">
                <button class="btn btn-media" type="button" :disabled="isTimerSaved === false || state == 'stop' || state == 'pause'" @click="stopCounter()">
                  <span class="icon-ico-stop"></span>
                </button>
              </div>
            </div>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import Swal from "sweetalert2";

export default {
  name: "ProceduralVoting",
  props: {
    room: {
      type: Object,
      default: () => {
        return {}
      } 
    }
  },
  data() {
    return {
      speakerTime: null,
      voting: null,
      minute: 1,
      second: 0,
      isTimerSaved: false,
      remaining: null,
      counter: null,
      participants: [],
      inFavourSpeakers: [],
      againtsSpeakers: [],
      activeSpeaker: null,
      state: null
    }
  },
  watch: {
    room() {
      this.getParticipants();
      this.getInFavourSpeakers();
      this.getAgaintsSpeakers();
      this.getTimer();
    },
    speakerTime(newVal) {
      const time = newVal.split(':');
      this.minute = Number(time[0])
      this.second = Number(time[1])
    }
  },
  computed: {
    timeLimit() {
      return moment().minute(this.minute).second(this.second).format('mm:ss')
    },
    progress() {
      if (this.remaining !== null && this.timeLimit !== null) {
        const currentMinute = moment(this.remaining, 'mm:ss').minute();
        const currentSecond = moment(this.remaining, 'mm:ss').second();
        const current = (currentMinute * 60) + currentSecond

        const endMinute = moment(this.timeLimit, 'mm:ss').minute();
        const endSecond = moment(this.timeLimit, 'mm:ss').second();
        const end = (endMinute * 60) + endSecond

        const progress = current / end * 100

        if (progress >= 100) {
          this.stopCounter()
        }

        return `${progress}%`
      } else {
        return `0%`
      }
    }
  },
  methods: {
    increment(type) {
      if (type == 'minute' && this.minute <= 59) {
        this.minute += 1;
      } else if (type == 'second' && this.second <= 59){
        this.second += 1;
      }
    },
    decrement(type) {
      if (type == 'minute' && this.minute > 0) {
        this.minute -= 1;
      } else if (type == 'second' && this.second > 0) {
        this.second -= 1;
      }
    },
    getTimer() {
      this.$axios.get(`/master/simulation/${this.room.id}/pv_show_timer`)
        .then(response => {
          this.isTimerSaved = true
          this.state = 'pause'
          this.voting = response.data.data.voting_on
          this.minute = response.data.data.minutes
          this.second = response.data.data.seconds

          const minute = this.minute < 10 ? `0${this.minute}` : `${this.minute}`
          const second = this.second < 10 ? `0${this.second}` : `${this.second}`
          this.speakerTime = `${minute}:${second}`
        })
        .catch(error => {
          console.error(error.response.data)
          this.isTimerSaved = false
        })
    },
    setTimer() {
      this.$axios.post(`/master/simulation/${this.room.id}/pv_set_time`, {
        voting_on: this.voting,
        minutes: this.minute,
        seconds: this.second
      }).then(response => {
          this.isTimerSaved = true
          this.state = 'pause'
          Swal.fire("Success", response.data.message, "success");
        })
    },
    resetTimer() {
      this.$axios.post(`/master/simulation/${this.room.id}/pv_set_time`, {
        voting_on: this.voting,
        minutes: 1,
        seconds: 0
      }).then(() => {
          this.isTimerSaved = false
          this.minute = 1
          this.second = 0
          this.speakerTime = null
          this.state = null
          clearInterval(this.counter);
          this.remaining = null;

          this.$emit('setCurrent', {
            tab: "Procedural Voting",
            title: "",
            country: "",
            flag: "",
            type: "reset",
            duration_hours: 0,
            duration_minutes: 0,
            duration_seconds: 0,
            minutes: this.minute,
            seconds: this.second,
            remaining_duration_hours: 0,
            remaining_duration_minutes: 0,
            remaining_duration_seconds: 0,
            remaining_minutes: this.remaining !== null ? moment(this.remaining, 'mm:ss').minute() : 0,
            remaining_seconds: this.remaining !== null ? moment(this.remaining, 'mm:ss').second() : 0,
          })
        })
    },
    getParticipants() {
      this.$axios.get(`/master/simulation/${this.room.id}/pv_list_participant`)
        .then(response => {
          this.participants = response.data.data
        })
    },
    addInFavourSpeaker(participant) {
      const index = this.inFavourSpeakers.findIndex(speaker => speaker.name == participant.name)
      if (index < 0) {
        this.$axios.post(`/master/simulation/${this.room.id}/pv_add_speaker_in_favour`, {
          participant_id: participant.id,
          index: this.inFavourSpeakers.length,
        })
          .then(async () => {
            await this.getInFavourSpeakers();
          })
      }
    },
    async getInFavourSpeakers() {
      await this.$axios.get(`/master/simulation/${this.room.id}/pv_list_speaker_in_favour`)
        .then(response => {
          this.inFavourSpeakers = response.data.data
        })
    },
    async changeInFavourSpeaker(step) {
      if (this.activeSpeaker) {
        const check = this.inFavourSpeakers.findIndex(speaker => speaker == this.activeSpeaker)
        if (check >= 0) {
          if (step > 0) { // next or down
            await this.$axios.post(`/master/simulation/${this.room.id}/pv_down_speaker_in_favour`, {
              _method: "PATCH",
              id: this.activeSpeaker.id,
            })
            .then(async () => {
              await this.getInFavourSpeakers();
            })
          } else { // previous or up
            await this.$axios.post(`/master/simulation/${this.room.id}/pv_up_speaker_in_favour`, {
              _method: "PATCH",
              id: this.activeSpeaker.id,
            })
            .then(async () => {
              await this.getInFavourSpeakers();
            })
          } 
        }
      }
    },
    async deleteInFavourSpeaker() {        
      if (this.activeSpeaker) {
        const check = this.inFavourSpeakers.findIndex(speaker => speaker == this.activeSpeaker)
        if (check >= 0) {
          await this.$axios.post(`/master/simulation/${this.room.id}/pv_remove_speaker_in_favour`, {
            _method: 'DELETE',
            pvil_id: this.activeSpeaker.id,
          })
            .then(async () => {
              await this.getInFavourSpeakers();
              this.activeSpeaker = null;
            })
          this.resetTimer()
        }
      }
    },
    clearInFavourSpeaker() {
      const check = this.inFavourSpeakers.findIndex(speaker => speaker == this.activeSpeaker)
      if (check >= 0) {
        this.activeSpeaker = null
      }
      this.inFavourSpeakers = []
      this.$axios.delete(`/master/simulation/${this.room.id}/pv_clear_speaker_in_favour`)
        .then(response => {
          console.log("clear speaker ", response.data)
        })
      this.resetTimer()
    },
    addAgaintsSpeaker(participant) {
      const index = this.againtsSpeakers.findIndex(speaker => speaker.name == participant.name)
      if (index < 0) {
        this.$axios.post(`/master/simulation/${this.room.id}/pv_add_speaker_againts`, {
          participant_id: participant.id,
          index: this.againtsSpeakers.length,
        })
          .then(async () => {
            await this.getAgaintsSpeakers();
          })
      }
    },
    async getAgaintsSpeakers() {
      await this.$axios.get(`/master/simulation/${this.room.id}/pv_list_speaker_againts`)
        .then(response => {
          this.againtsSpeakers = response.data.data
        })
    },
    async changeAgaintsSpeaker(step) {
      if (this.activeSpeaker) {
        const check = this.againtsSpeakers.findIndex(speaker => speaker == this.activeSpeaker)
        if (check >= 0) {
          if (step > 0) { // next or down
            await this.$axios.post(`/master/simulation/${this.room.id}/pv_down_speaker_againts`, {
              _method: "PATCH",
              id: this.activeSpeaker.id,
            })
            .then(async () => {
              await this.getAgaintsSpeakers();
            })
          } else { // previous or up
            await this.$axios.post(`/master/simulation/${this.room.id}/pv_up_speaker_againts`, {
              _method: "PATCH",
              id: this.activeSpeaker.id,
            })
            .then(async () => {
              await this.getAgaintsSpeakers();
            })
          } 
        }
      }
    },
    async deleteAgaintsSpeaker() {        
      if (this.activeSpeaker) {
        const check = this.againtsSpeakers.findIndex(speaker => speaker == this.activeSpeaker)
        if (check >= 0) {
          await this.$axios.post(`/master/simulation/${this.room.id}/pv_remove_speaker_againts`, {
            _method: 'DELETE',
            pvag_id: this.activeSpeaker.id,
          })
            .then(async () => {
              await this.getAgaintsSpeakers();
              this.activeSpeaker = null;
            })
          this.resetTimer()
        }
      }
    },
    clearAgaintsSpeaker() {
      const check = this.againtsSpeakers.findIndex(speaker => speaker == this.activeSpeaker)
      if (check >= 0) {
        this.activeSpeaker = null
      }
      this.againtsSpeakers = []
      this.$axios.delete(`/master/simulation/${this.room.id}/pv_clear_speaker_againts`)
        .then(response => {
          console.log("clear speaker ", response.data)
        })
      this.resetTimer()
    },
    async setActiveSpeaker(speaker, type) {
      if (this.state == 'start') {
        if (this.activeSpeaker.type == 'favour') {
          await this.$axios.post(`/master/simulation/${this.room.id}/pv_stop_timer_in_favour`, {
            _method: 'PATCH',
            pvil_id: this.activeSpeaker.id,
          }).then(response => {
              console.log(response.data)
            })
        } else {
          await this.$axios.post(`/master/simulation/${this.room.id}/pv_stop_timer_againts`, {
            _method: 'PATCH',
            pvag_id: this.activeSpeaker.id,
          }).then(response => {
              console.log(response.data)
            })
        }
      }

      this.activeSpeaker = Object.assign(speaker, { type: type});
      this.state = 'pause'
      this.remaining = null
      clearInterval(this.counter)
    },
    playCounter() {
      this.state = 'start'
      let count = this.remaining !== null ? this.remaining : moment(this.timeLimit, 'mm:ss').minute(0).second(0)
      if (moment(count, 'mm:ss').isBefore(moment(this.timeLimit, 'mm:ss'))) {
        if (this.activeSpeaker.type == 'favour') {
          this.$axios.post(`/master/simulation/${this.room.id}/pv_start_timer_in_favour`, {
            _method: 'PATCH',
            pvil_id: this.activeSpeaker.id,
          }).then(response => {
            console.log(response.data)
          })
        } else {
          this.$axios.post(`/master/simulation/${this.room.id}/pv_start_timer_againts`, {
            _method: 'PATCH',
            pvag_id: this.activeSpeaker.id,
          }).then(response => {
            console.log(response.data)
          })
        }
      }
      this.counter = setInterval(() => {
        if (moment(count, 'mm:ss').isBefore(moment(this.timeLimit, 'mm:ss'))) {
          count =  moment(count, 'mm:ss').add(1, 'second').format('mm:ss')
          this.remaining = count;
        }
      }, 1000)
      this.$emit('setCurrent', {
        tab: "Procedural Voting",
        title: "",
        country: this.activeSpeaker.name,
        flag: this.activeSpeaker.flag,
        type: "start",
        duration_hours: 0,
        duration_minutes: 0,
        duration_seconds: 0,
        minutes: this.minute,
        seconds: this.second,
        remaining_duration_hours: 0,
        remaining_duration_minutes: 0,
        remaining_duration_seconds: 0,
        remaining_minutes: this.remaining !== null ? moment(this.remaining, 'mm:ss').minute() : 0,
        remaining_seconds: this.remaining !== null ? moment(this.remaining, 'mm:ss').second() : 0,
      })
    },
    pauseCounter() {
      this.state = 'pause'
      clearInterval(this.counter)
      if (this.activeSpeaker.type == 'favour') {
        this.$axios.post(`/master/simulation/${this.room.id}/pv_pause_timer_in_favour`, {
          _method: 'PATCH',
          pvil_id: this.activeSpeaker.id,
        }).then(response => {
          console.log(response.data)
        })
      } else {
        this.$axios.post(`/master/simulation/${this.room.id}/pv_pause_timer_againts`, {
          _method: 'PATCH',
          pvag_id: this.activeSpeaker.id,
        }).then(response => {
          console.log(response.data)
        })
      }

      this.$emit('setCurrent', {
        tab: "Procedural Voting",
        title: "",
        country: this.activeSpeaker.name,
        flag: this.activeSpeaker.flag,
        type: "pause",
        duration_hours: 0,
        duration_minutes: 0,
        duration_seconds: 0,
        minutes: this.minute,
        seconds: this.second,
        remaining_duration_hours: 0,
        remaining_duration_minutes: 0,
        remaining_duration_seconds: 0,
        remaining_minutes: moment(this.remaining, 'mm:ss').minute(),
        remaining_seconds: moment(this.remaining, 'mm:ss').second(),
      })
    },
    stopCounter() {
      this.state = 'stop'
      clearInterval(this.counter)
      this.remaining = this.timeLimit
      if (this.activeSpeaker.type == 'favour') {
        this.$axios.post(`/master/simulation/${this.room.id}/pv_stop_timer_in_favour`, {
          _method: 'PATCH',
          pvil_id: this.activeSpeaker.id,
        }).then(response => {
          console.log(response.data)
        })
      } else {
        this.$axios.post(`/master/simulation/${this.room.id}/pv_stop_timer_againts`, {
          _method: 'PATCH',
          pvag_id: this.activeSpeaker.id,
        }).then(response => {
          console.log(response.data)
        })
      }
      this.$emit('setCurrent', {
        tab: "Procedural Voting",
        title: "",
        country: this.activeSpeaker.name,
        flag: this.activeSpeaker.flag,
        type: "stop",
        duration_hours: 0,
        duration_minutes: 0,
        duration_seconds: 0,
        minutes: this.minute,
        seconds: this.second,
        remaining_duration_hours: 0,
        remaining_duration_minutes: 0,
        remaining_duration_seconds: 0,
        remaining_minutes: 0,
        remaining_seconds: 0,
      })
    }
  }
}
</script>

<style scoped>
button:disabled {
  pointer-events: auto !important;
  cursor: not-allowed !important;
}
input:read-only {
  background-color: white !important;
}
.activeb {
  color: red !important;
  background-color: bisque !important
}
</style>